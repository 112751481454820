import Vue from 'vue'
import VueRouter from 'vue-router'
import Meta from 'vue-meta';
import store from '@/store'
Vue.use(VueRouter)
Vue.use(Meta);
const routes = [
  {
    path: '/',
    redirect: 'dashboard',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
    meta: {
      key: 'dashboard',
      auth: true
    }
  },
  {
    path: '/staff',
    name: 'staff',
    component: () => import('@/views/staff/Staff.vue'),
    meta: {
      key: 'staff',
      auth: true
    }
  },
  {
    path: '/staff/add',
    name: 'staff-form-add',
    component: () => import('@/views/staff/StaffAdd.vue'),
    meta: {
      key: 'staff',
      auth: true
    }
  },
  {
    path: '/staff/staff_form_update/:id',
    name: 'staff-form',
    component: () => import('@/views/staff/StaffForm.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'staff',
      auth: true
    }
  },
  {
    path: '/user',
    name: 'user',
    component: () => import('@/views/user/User.vue'),
    meta: {
      key: 'user',
      auth: true
    }
  },
  {
    path: '/user/add',
    name: 'user-form-add',
    component: () => import('@/views/user/UserAdd.vue'),
    meta: {
      key: 'userAdd',
      auth: true
    }
  },
  {
    path: '/userLastPlay',
    name: 'userLastPlay',
    component: () => import('@/views/user/UserLastPlay.vue'),
    meta: {
      key: 'userLastPlay',
      auth: true
    }
  },
  {
    path: '/user/user_form_update/:id',
    name: 'user-form',
    component: () => import('@/views/user/UserForm.vue'),
    meta: {
      key: 'user',
      auth: true
    }
  },
  {
    path: '/agent',
    name: 'agent',
    component: () => import('@/views/agent/Agent.vue'),
    meta: {
      key: 'agent',
      auth: true
    }
  },
  {
    path: '/agent/commission/:id',
    name: 'agent-commission',
    component: () => import('@/views/agent/AgentCommission.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'agent',
      auth: true
    }
  },
  {
    path: '/agent/reportmember/:id',
    name: 'agent-report-member',
    component: () => import('@/views/agent/AgentReportMember.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'agent',
      auth: true
    }
  },
  {
    path: '/agent/reportmember_register/:id',
    name: 'agent-report-member-register',
    component: () => import('@/views/agent/AgentReportMemberRegister.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'agent',
      auth: true
    }
  },
  {
    path: '/agent/reportmember_totalplay/:id',
    name: 'agent-report-member-total-play',
    component: () => import('@/views/agent/AgentReportMemberTotalPlay.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'agent',
      auth: true
    }
  },
  {
    path: '/ref',
    name: 'ref',
    component: () => import('@/views/ref/Ref.vue'),
    meta: {
      key: 'ref',
      auth: true
    }
  },
  {
    path: '/bonus',
    name: 'ref-bonus',
    component: () => import('@/views/ref/Bonus.vue'),
    meta: {
      key: 'bonus',
      auth: true
    }
  },
  {
    path: '/bonus-summary',
    name: 'bonus-summary',
    component: () => import('@/views/bonus/Bonus.vue'),
    meta: {
      key: 'reportBonus',
      auth: true
    }
  },
  {
    path: '/returnbalance',
    name: 'ref-returnbalance',
    component: () => import('@/views/ref/Returnbalance.vue'),
    meta: {
      key: 'returnbalance',
      auth: true
    }
  },
  {
    path: '/coupon',
    name: 'coupon',
    component: () => import('@/views/coupon/Coupon.vue'),
    meta: {
      key: 'coupon',
      auth: true
    }
  },
  {
    path: '/coupon/coupon_list/:name/:createdAt',
    name: 'coupon-list',
    component: () => import('@/views/coupon/CouponList.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'coupon',
      auth: true
    }
  },
  {
    path: '/coupon/coupon_use_list/:id',
    name: 'coupon-use-list',
    component: () => import('@/views/coupon/CouponUseList.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'coupon',
      auth: true
    }
  },
  {
    path: '/creditwait',
    name: 'creditwait',
    component: () => import('@/views/transactions/CreditWait.vue'),
    meta: {
      key: 'creditwait',
      auth: true
    }
  },
  {
    path: '/deposit/deposit_form_detail/:id',
    name: 'deposit-form',
    component: () => import('@/views/transactions/DepositForm.vue'),
    meta: {
      key: 'deposit',
      auth: true
    }
  },
  {
    path: '/deposit',
    name: 'deposit',
    component: () => import('@/views/transactions/Deposit.vue'),
    meta: {
      key: 'deposit',
      auth: true
    }
  },
  {
    path: '/credit',
    name: 'credit',
    component: () => import('@/views/transactions/Credit.vue'),
    meta: {
      key: 'credit',
      auth: true
    }
  },
  {
    path: '/user-deposit',
    name: 'user-deposit',
    component: () => import('@/views/transactions/UserDeposit.vue'),
    meta: {
      key: 'credit',
      auth: true
    }
  },
  {
    path: '/withdraw',
    name: 'withdraw',
    component: () => import('@/views/transactions/Withdraw.vue'),
    meta: {
      key: 'withdraw',
      auth: true
    }
  },
  {
    path: '/withdraw-form/:id',
    name: 'withdraw-form',
    component: () => import('@/views/transactions/WithdrawForm.vue'),
    meta: {
      key: 'withdraw',
      auth: true
    }
  },
  {
    path: '/transferOut',
    name: 'TransferOut',
    component: () => import('@/views/transactions/TransferOut.vue'),
    meta: {
      key: 'transferOut',
      auth: true
    }
  },
  {
    path: '/paykrubTransferOut',
    name: 'paykrubTransferOut',
    component: () => import('@/views/paykrub/PaykrubTransferOut.vue'),
    meta: {
      key: 'paykrubTransferOut',
      auth: true
    }
  },
  {
    path: '/paykrubTransferOutAdd',
    name: 'paykrubTransferOutAdd',
    component: () => import('@/views/paykrub/PaykrubTransferOutAdd.vue'),
    meta: {
      key: 'paykrubTransferOutAdd',
      auth: true
    }
  },
  {
    path: '/logDepositWithdraw',
    name: 'log-deposit-withdraw',
    component: () => import('@/views/logs/LogDepositWithdraw.vue'),
    meta: {
      key: 'logDepositWithdraw',
      auth: true
    }
  },
  {
    path: '/logAccount',
    name: 'log-account',
    component: () => import('@/views/logs/LogAccount.vue'),
    meta: {
      key: 'logAccount',
      auth: true
    }
  },
  {
    path: '/logSms',
    name: 'log-sms',
    component: () => import('@/views/logs/LogSms.vue'),
    meta: {
      key: 'logSms',
      auth: true
    }
  },
  {
    path: '/logPage',
    name: 'log-page',
    component: () => import('@/views/logs/LogPage.vue'),
    meta: {
      key: 'logPage',
      auth: true
    }
  },
  {
    path: '/logExport',
    name: 'log-export',
    component: () => import('@/views/logs/LogExport.vue'),
    meta: {
      key: 'logExport',
      auth: true
    }
  },
  {
    path: '/logLineNotify',
    name: 'log-line-notify',
    component: () => import('@/views/logs/LogLineNotify.vue'),
    meta: {
      key: 'logLineNotify',
      auth: true
    }
  },
  {
    path: '/logWheel',
    name: 'log-wheel',
    component: () => import('@/views/logs/LogWheel.vue'),
    meta: {
      key: 'logWheel',
      auth: true
    }
  },
  {
    path: '/logCoupon',
    name: 'log-coupon',
    component: () => import('@/views/logs/LogCoupon.vue'),
    meta: {
      key: 'logCoupon',
      auth: true
    }
  },
  // {
  //   path: '/logTransferOut',
  //   name: 'log-transfer-out',
  //   component: () => import('@/views/logs/LogTransferOut.vue'),
  //   meta: {
  //     key: 'logTransferOut',
  //     auth: true
  //   }
  // },
  {
    path: '/logBank',
    name: 'log-bank',
    component: () => import('@/views/logs/LogBank.vue'),
    meta: {
      key: 'logBank',
      auth: true
    }
  },
  // {
  //   path: '/logCashBack',
  //   name: 'log-cash-back',
  //   component: () => import('@/views/logs/LogCashBack.vue'),
  //   meta: {
  //     key: 'logTransferOut',
  //     auth: true
  //   }
  // },
  {
    path: '/log-point-coin',
    name: 'log-point-coin',
    component: () => import('@/views/logs/LogPointCoin.vue'),
    meta: {
      key: 'logPointCoin',
      auth: true
    }
  },
  {
    path: '/promotion',
    name: 'promotion',
    component: () => import('@/views/settings/promotion/Promotion.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'promotion',
      auth: true
    }
  },
  {
    path: '/promotion/promotion_form_create',
    name: 'promotion-form-create',
    component: () => import('@/views/settings/promotion/PromotionFormCreate.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'promotion',
      auth: true
    }
  },
  {
    path: '/promotion/promotion_form_update/:id',
    name: 'promotion-form-update',
    component: () => import('@/views/settings/promotion/PromotionFormEdit.vue'),
    meta: {
      key: 'promotion',
      auth: true
    }
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('@/views/settings/news/News.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'news',
      auth: true
    }
  },
  {
    path: '/news/new_form_create',
    name: 'news-form-create',
    component: () => import('@/views/settings/news/NewsFormCreate.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'news',
      auth: true
    }
  },
  {
    path: '/news/news_form_update/:id',
    name: 'news-form-update',
    component: () => import('@/views/settings/news/NewsFormEdit.vue'),
    meta: {
      key: 'news',
      auth: true
    }
  },
  {
    path: '/announce',
    name: 'announce',
    component: () => import('@/views/settings/announce/Announce.vue'),
    meta: {
      permissions: {
        admin: true,
      },
      key: 'announce',
      auth: true
    }
  },
  {
    path: '/announce/announce_form_create',
    name: 'announce/announce-form-create',
    component: () => import('@/views/settings/announce/AnnounceFormCreate.vue'),
    meta: {
      permissions: {
        admin: true,
      },
      key: 'announce',
      auth: true
    }
  },
  {
    path: '/messageBoard',
    name: 'messageBoard',
    component: () => import('@/views/settings/message-board/MessageBoard.vue'),
    meta: {
      permissions: {
        admin: true,
      },
      key: 'messageBoard',
      auth: true
    }
  },
  {
    path: '/settingWebsite',
    name: 'web_setting',
    component: () => import('@/views/settings/WebSetting.vue'),
    meta: {
      key: 'settingWebsite',
      auth: true
    }
  },
  {
    path: '/settingBank',
    name: 'bank',
    component: () => import('@/views/settings/bank/Banks.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'settingBank',
      auth: true
    }
  },
  {
    path: '/bank/bank_form_create',
    name: 'bank-form-create',
    component: () => import('@/views/settings/bank/BankFormCreate.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'settingBank',
      auth: true
    }
  },
  {
    path: '/bank/bank_form_update/:id',
    name: 'bank-form-update',
    component: () => import('@/views/settings/bank/BankFormEdit.vue'),
    meta: {
      key: 'settingBank',
      auth: true
    }
  },
  // {
  //   path: '/reportBonus',
  //   name: 'report-add-bonus',
  //   component: () => import('@/views/report/AddBonus.vue'),
  //   meta: {
  //     key: 'reportBonus',
  //     auth: true
  //   }
  // },
  {
    path: '/reportDepositWithdraw',
    name: 'report-deposit-and-withdraw',
    component: () => import('@/views/report/DepositAndWithdraw'),
    meta: {
      key: 'logTransferOut',
      auth: true
    }
  },
  {
    path: '/simple-table',
    name: 'simple-table',
    component: () => import('@/views/simple-table/SimpleTable.vue'),
  },
  {
    path: '/login',
    name: 'pages-login',
    component: () => import('@/views/pages/Login.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '/ak-feature-pages',
    name: 'ak-feature-pages',
    component: () => import('@/views/feature-pages/FeaturePages.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'settingWebsite',
      auth: true
    }
  },
  // acounting report
  {
    path: '/accounting-deposit-report',
    name: 'accounting-deposit-report',
    component: () => import('@/views/accounting-report/AccountingDepositReport.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'accountingDepositReport',
      auth: true
    }
  },
  {
    path: '/accounting-withdraw-report',
    name: 'accounting-withdraw-report',
    component: () => import('@/views/accounting-report/AccountingWithdrawReport.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'accountingWithdrawReport',
      auth: true
    }
  },
  // end
  {
    path: '/logCashbackSetting',
    name: 'log-cashback-setting',
    component: () => import('@/views/logs/LogCashbackSetting.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'logCashbackSetting',
      auth: true
    }
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },

  //// Activities - Royalty Program (CRM) ////
  {
    path: '/dailyLogin',
    name: 'daily-login',
    component: () => import('@/views/daily-login/DailyLogin.vue'),
    meta: {
      key: 'dailyLogin',
      auth: true
    }
  },
  {
    path: '/dailyLogin/add',
    name: 'daily-login-add',
    component: () => import('@/views/daily-login/DailyLoginAdd.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'dailyLogin',
      auth: true
    }
  },
  {
    path: '/dailyLogin/update/:id',
    name: 'daily-login-form',
    component: () => import('@/views/daily-login/DailyLoginForm.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'dailyLogin',
      auth: true
    }
  },
  {
    path: '/luckyCard',
    name: 'lucky-card',
    component: () => import('@/views/lucky-card/LuckyCard.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'luckyCard',
      auth: true
    }
  },
  {
    path: '/predictLotto',
    name: 'predict-lotto',
    component: () => import('@/views/predict-lotto/PredictLotto.vue'),
    meta: {
      key: 'predictLotto',
      auth: true
    }
  },
  {
    path: '/predictLotto/add',
    name: 'predict-lotto-add',
    component: () => import('@/views/predict-lotto/PredictLottoAdd.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'predictLotto',
      auth: true
    }
  },
  {
    path: '/predictLotto/update/:id',
    name: 'predict-lotto-form',
    component: () => import('@/views/predict-lotto/PredictLottoForm.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'predictLotto',
      auth: true
    }
  },
  {
    path: '/predictLotto/update-result/:id',
    name: 'predict-lotto-result-form',
    component: () => import('@/views/predict-lotto/PredictLottoResultForm.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'predictLotto',
      auth: true
    }
  },
  {
    path: '/predictLotto/user/:id',
    name: 'predict-lotto-user',
    component: () => import('@/views/predict-lotto/PredictLottoUser.vue'),
    meta: {
      key: 'predictLotto',
      auth: true
    }
  },
  {
    path: '/predictLotto/result/:id',
    name: 'predict-lotto-result',
    component: () => import('@/views/predict-lotto/PredictLottoResult.vue'),
    meta: {
      key: 'predictLotto',
      auth: true
    }
  },

  {
    path: '/predictFootball',
    name: 'predict-football',
    component: () => import('@/views/predict-football/PredictFootball.vue'),
    meta: {
      key: 'predictFootball',
      auth: true
    }
  },
  {
    path: '/predictFootball/add',
    name: 'predict-football-add',
    component: () => import('@/views/predict-football/PredictFootballAdd.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'predictFootball',
      auth: true
    }
  },
  {
    path: '/predictFootball/update/:id',
    name: 'predict-football-form',
    component: () => import('@/views/predict-football/PredictFootballForm.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'predictFootball',
      auth: true
    }
  },
  {
    path: '/predictFootball/update-result/:id',
    name: 'predict-football-result-form',
    component: () => import('@/views/predict-football/PredictFootballResultForm.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'predictFootball',
      auth: true
    }
  },
  {
    path: '/predictFootball/user/:id',
    name: 'predict-football-user',
    component: () => import('@/views/predict-football/PredictFootballUser.vue'),
    meta: {
      key: 'predictFootball',
      auth: true
    }
  },
  {
    path: '/predictFootball/result/:id',
    name: 'predict-football-result',
    component: () => import('@/views/predict-football/PredictFootballResult.vue'),
    meta: {
      key: 'predictFootball',
      auth: true
    }
  },
  {
    path: '/spadehigh',
    name: 'spadehigh',
    component: () => import('@/views/spadehigh/SpadeHigh.vue'),
    meta: {
      key: 'spadeHigh',
      auth: true
    }
  },
  {
    path: '/minesweeper',
    name: 'minesweeper',
    component: () => import('@/views/minesweeper/MineSweeper.vue'),
    meta: {
      key: 'mineSweeper',
      auth: true
    }
  },


  //// Activities - Royalty Program (CRM) ////

  {
    path: '/pointCoinExp',
    name: 'point-coin-exp',
    component: () => import('@/views/point-coin-exp/PointCoinExp.vue'),
    meta: {
      key: 'pointCoinExp',
      auth: true
    }
  },
  {
    path: '/bonusExp',
    name: 'bonus-exp',
    component: () => import('@/views/bonus-exp/BonusExp.vue'),
    meta: {
      key: 'bonusExp',
      auth: true
    }
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('@/views/settings/video/Video.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'video',
      auth: true
    }
  },
  {
    path: '/video/video_form_create',
    name: 'video-form-create',
    component: () => import('@/views/settings/video/VideoAdd.vue'),
    meta: {
      permissions: {
        admin: true,
        member: true,
      },
      key: 'video',
      auth: true
    }
  },
  {
    path: '/video/video_form_update/:id',
    name: 'video-form-update',
    component: () => import('@/views/settings/video/VideoForm.vue'),
    meta: {
      key: 'video',
      auth: true
    }
  },
  {
    path: '/redeemreward',
    name: 'redeemreward',
    component: () => import('@/views/redeemreward/RedeemReward.vue'),
    meta: {
      key: 'redeemReward',
      auth: true
    },

  },
  {
    path: '/redeemreward/add',
    name: 'redeemreward-add',
    component: () => import('@/views/redeemreward/RedeemRewardAdd.vue'),
    meta: {
      key: 'redeemReward',
      auth: true
    },
  },
  {
    path: '/redeemreward/history',
    name: 'redeemreward-history',
    component: () => import('@/views/redeemreward/RedeemRewardHistory.vue'),
    meta: {
      key: 'redeemReward',
      auth: true
    },
  },
  {
    path: '/activitybanner',
    name: 'activitybanner',
    component: () => import('@/views/activitybanner/ActivityBanner.vue'),
    meta: {
      key: 'activityBanner',
      auth: true
    },

  },
  {
    path: '/activitybanner/add',
    name: 'activitybanner-add',
    component: () => import('@/views/activitybanner/ActivityBannerAdd.vue'),
    meta: {
      key: 'activityBanner',
      auth: true
    },
  },
  {
    path: '/rankingreward',
    name: 'rankingreward',
    component: () => import('@/views/rankingreward/RankingReward.vue'),
    meta: {
      key: 'rankingReward',
      auth: true
    },

  },
  {
    path: '/rankingreward/add',
    name: 'rankingreward-add',
    component: () => import('@/views/rankingreward/RankingRewardAdd.vue'),
    meta: {
      key: 'rankingReward',
      auth: true
    },

  },
]

const router = new VueRouter({
  mode: 'history',
  base: 'admin',
  routes,
})

router.beforeEach(async (to, from, next) => {
  store.dispatch('startLoad')
  if (!to.name) {
    next({ name: 'error-404' });
  }
  const permissions = to.matched.length > 0 ? to.matched[0].meta.key : ''
  const auth = to.matched.length > 0 ? to.matched[0].meta.auth : false
  if (auth) {
    if (store.getters.getToken) {
      await store.dispatch('permissions')
      let psSystem = store.getters.getPermissions
      let isShowTransferOut = false;
      let isShowCoupon = false;
      await store.dispatch('getSettingFeature').then(res => {
        const chkShowTransferOut = res.filter((item) => item.page === 'transferOut')[0]?.value === 1 ? true : false;
        isShowTransferOut = chkShowTransferOut;

        const chkShowCoupon = res.filter((item) => item.page === 'coupon')[0]?.value === 1 ? true : false;
        isShowCoupon = chkShowCoupon;
      })
      if (psSystem.includes(permissions)) {
        if ((to.meta.key === 'transferOut' && !isShowTransferOut) || (to.meta.key === 'coupon' && !isShowCoupon)) {
          next({ name: 'error-404' });
        } else {
          next();
        }
      } else {
        if (to.meta.key === 'dashboard') {
          next({ name: '' });
        } else {
          next({ name: 'error-404' });
        }
      }
    } else {
      next({ name: 'pages-login' });
    }
  } else {
    next();
  }
  store.dispatch('finishLoad')
})
export default router
