import { AUTH_API_BASE_URL, urlFor, request } from '../index';

export async function getAccountingRepositDeposit(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/accounting-report/deposit?${params}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function getAccountingRepositWithdraw(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/accounting-report/withdraw?${params}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}
