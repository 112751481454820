import { AUTH_API_BASE_URL, urlFor, request } from '../index';

export async function addUser(data) {
    try {
      return request('post', urlFor(AUTH_API_BASE_URL, '/user/add'), {
        auth: true,
        data
      });
    } catch (e) {
      return e
    }
}
