import { AUTH_API_BASE_URL, urlFor, request } from '../index'

export async function getSpadeHighData(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/spade-high/list?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function saveSpadeHighData(params) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/spade-high/add?name=spadehigh`), {
      auth: true,
      data: params,
    })
  } catch (e) {
    return e
  }
}


