import * as AffiliateService from '@/services/apis/affiliate'
const state = {
}

const actions = {
    async getAffiliate({ }, params) {
        try {
            let text = ""
            for (let i in params) {
                text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
            }
            const res = await AffiliateService.getAffiliate(text)
            return res.data
        } catch (err) {
            if(err.message){ throw JSON.parse(err.message) }
        }
    },
    async getAffiliateTotal({ }, params) {
        try {
            const res = await AffiliateService.getAffiliateTotal(params)
            return res.data
        } catch (err) {
            if(err.message){ throw JSON.parse(err.message) }
        }
    },
    async getAffiliateLastPlay({ }, params) {
      try {
          const res = await AffiliateService.getAffiliateLastPlay(params)
          return res.data
      } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
      }
  },
}

export default {
    state,
    actions,
}
