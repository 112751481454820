import * as LuckyCardService from '@/services/apis/luckyCard'
const state = {}

const actions = {
  async getLuckyCard({}, params) {
    try {
      const res = await LuckyCardService.getLuckyCard(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async addLuckyCard({}, params) {
    try {
      const res = await LuckyCardService.addLuckyCard(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
}

export default {
  state,
  actions,
}
