import { AUTH_API_BASE_URL, urlFor, request } from '../index';

export async function getMessageBoard(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/messageboard/list?${params}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function uppdateMessageBoard(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, '/messageboard/update'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}

export async function deleteMessageBoard(id) {
  try {
    return request('delete', urlFor(AUTH_API_BASE_URL, `/setting-web/update/deleteannounce?id=${id}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}
