import * as RedeemRewardService from '@/services/apis/redeemReward'
const state = {}

const actions = {
  async getRedeemReward({ }, params) {
    try {
      const res = await RedeemRewardService.getRedeemReward(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async getRedeemRewardHistory({ }, params) {
    try {
      const res = await RedeemRewardService.getRedeemRewardHistory(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async updateRedeemRewardStatus({ }, data) {
    try {
      const res = await RedeemRewardService.updateRedeemRewardStatus(data)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async addRedeemReward({ }, data) {
    try {
      const res = await RedeemRewardService.addRedeemReward(data)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },

  async removeRedeemReward({ }, id) {
    try {
      const res = await RedeemRewardService.removeRedeemReward(id)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async updateRedeemRewardHistoryStatus({ }, data) {
    try {
      const res = await RedeemRewardService.updateRedeemRewardHistoryStatus(data)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
}

export default {
  state,
  actions,
}
