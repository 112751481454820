import * as LogsService from '@/services/apis/logs'


const actions = {
  async getLogDepositWithdraw({ }, params) {
      try {
          const res = await LogsService.getLogDepositWithdraw(params)
          return res.data
      } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
      }
  },
  async getLogSMS({ }, params) {
      try {
          const res = await LogsService.getLogSMS(params)
          return res.data
      } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
      }
  },
  async getLogWheel({ }, params) {
      try {
          const res = await LogsService.getLogWheel(params)
          return res.data
      } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
      }
  },
  async getLogCoupon({ }, params) {
    try {
        const res = await LogsService.getLogCoupon(params)
        return res.data
    } catch (err) {
        if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getLogLineNotify({ }, params) {
      try {
          const res = await LogsService.getLogLineNotify(params)
          return res.data
      } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
      }
  },
  async getLogEditMember({ }, params) {
      try {
          const res = await LogsService.getLogEditMember(params)
          return res.data
      } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
      }
  },
  async getLogRequest({ }, params) {
      try {
          const res = await LogsService.getLogRequest(params)
          return res.data
      } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
      }
  },
  async getLogCashback({ }, params) {
      try {
          const res = await LogsService.getLogCashback(params)
          return res.data
      } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
      }
  },
  async addLogPage({ }, data) {
      try {
          const res = await LogsService.addLogPage(data)
          return res.data
      } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
      }
  },
  async getLogCashbackSetting({ }, params) {
      try {
          const res = await LogsService.getLogCashbackSetting(params)
          return res.data
      } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
      }
},
  async getLogExportCSV({ }, params) {
    try {
        const res = await LogsService.getLogExportCSV(params)
        return res.data
    } catch (err) {
        if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async addLogExportCSV({ }, data) {
    try {
        const res = await LogsService.addLogExportCSV(data)
        return res.data
    } catch (err) {
        if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async checkLogEnterSecure({ }, data) {
    try {
        const res = await LogsService.checkLogEnterSecure(data)
        return res.data
    } catch (err) {
        if(err.message){ throw JSON.parse(err.message) }
    }
  },
}

export default {
    actions,
}
