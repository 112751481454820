import { AUTH_API_BASE_URL, urlFor, request } from '../index';

export async function getPromotion(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/promotion?${params}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function getPromotionUse(id) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/promotion/use/${id}`), {
      auth: true,
    });
  } catch (e) {
    return e
  }
}

export async function addPromotion(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, '/promotion/add'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}

export async function updatePromotion(data) {
  try {
    return request('patch', urlFor(AUTH_API_BASE_URL, '/promotion/update'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}
export async function deletePromotion(id) {
  try {
    return request('delete', urlFor(AUTH_API_BASE_URL, `/promotion/${id}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function addDepositGames(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, '/deposit/games'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}
