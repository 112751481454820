import * as AnnounceService from '@/services/apis/announce'
const state = {
}

const actions = {
  async getAnnounce({ }, params) {
    try {
      const res = await AnnounceService.getAnnounce(params)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async addAnnounce({ }, data) {
    try {
      const res = await AnnounceService.addAnnounce(data)
      if (res.status === 201) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async deleteAnnounce({ }, id) {
    try {
      const res = await AnnounceService.deleteAnnounce(id)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
}

export default {
  state,
  actions,
}
