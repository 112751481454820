import * as SpadeHighService from '@/services/apis/spadeHigh'
const state = {}

const actions = {
  async getSpadeHighData({ }, params) {
    try {
      const res = await SpadeHighService.getSpadeHighData(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },

  async saveSpadeHighData({ }, params) {
    try {
      const res = await SpadeHighService.saveSpadeHighData(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
}

export default {
  state,
  actions,
}
