import * as TransactionService from '@/services/apis/transaction'
const state = {}

const actions = {
  async getDeposit({}, params) {
    try {
      let text = ''
      for (let i in params) {
        text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
      }
      const res = await TransactionService.getDeposit(text)
      if (res.status === 200) {
        return res.data
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async addDeposit({}, params) {
    try {
      const res = await TransactionService.addDeposit(params)
      if (res.status === 201 || res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async updateDeposit({}, params) {
    try {
      const res = await TransactionService.updateDeposit(params)
      if (res.status === 201 || res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getTranferOut({}, params) {
    try {
      let text = ''
      for (let i in params) {
        text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
      }
      const res = await TransactionService.getTransfer(text)
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async addTransfer({}, params) {
    try {
      const res = await TransactionService.addTransfer(params)
      if (res.status === 201 || res.status === 200) {
        return res.data
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async addTransferOut({}, params) {
    try {
      const res = await TransactionService.addTransferOut(params)
      if (res.status === 201 || res.status === 200) {
        return res.data
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async updateTransfer({}, params) {
    try {
      const res = await TransactionService.updateTransfer(params)
      if (res.status === 201 || res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getWithdraw({}, params) {
    try {
      let text = ''
      for (let i in params) {
        text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
      }
      const res = await TransactionService.getWithdraw(text)
      if (res.status === 200 || res.status === 201) {
        return res.data
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async addWithdraw({}, params) {
    try {
      const res = await TransactionService.addWithdraw(params)
      if (res.status === 201 || res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async updateWithdraw({}, params) {
    try {
      const res = await TransactionService.updateWithdraw(params)
      if (res.status === 201 || res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async updateWithdrawTrueWallet({}, params) {
    try {
      const res = await TransactionService.updateWithdrawTrueWallet(params)
      if (res.status === 201 || res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async updateWithdrawPaykrub({}, params) {
    try {
      const res = await TransactionService.updateWithdrawPaykrub(params)
      if (res.status === 201 || res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async updateWithdrawCloudpay({}, params) {
    try {
      const res = await TransactionService.updateWithdrawCloudpay(params)
      if (res.status === 201 || res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async updateWithdrawJompay({}, params) {
    try {
      const res = await TransactionService.updateWithdrawJompay(params)
      if (res.status === 201 || res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async updateWithdrawHypay({}, params) {
    try {
      const res = await TransactionService.updateWithdrawHypay(params)
      if (res.status === 201 || res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getAccountBankDeposit({}) {
    try {
      const res = await TransactionService.getAccountBankDeposit()
      if (res.status === 201 || res.status === 200) {
        return res.data
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getStampDeposit({}, params) {
    try {
      let text = ''
      for (let i in params) {
        text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
      }
      const res = await TransactionService.getStampDeposit(text)
      if (res.status === 200) {
        return res.data
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async addDepositGames({}, params) {
    try {
      const res = await TransactionService.addDepositGames(params)
      if (res.status === 201 || res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async cancelDepositGames({}, params) {
    try {
      const res = await TransactionService.cancelDepositGames(params)
      if (res.status === 201 || res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
}

export default {
  state,
  actions,
}
