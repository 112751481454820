import { AUTH_API_BASE_URL, urlFor, request } from '../index'

export async function getLogDepositWithdraw(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/logger/deposit-withdraw?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getLogSMS(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/logger/sms?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getLogWheel(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/logger/wheel?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getLogCoupon(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/logger/coupon?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getLogLineNotify(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/logger/line-notify?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getLogEditMember(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/logger/edit-member?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getLogRequest(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/logger/page?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getLogCashback(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/cashback/transferlog?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function addLogPage(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, '/logger/add-page'), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}

export async function getLogCashbackSetting(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/logger/cashback-setting?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getLogExportCSV(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/log-admin-export?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function addLogExportCSV(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/log-admin-export/create-log`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}

export async function checkLogEnterSecure(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/secure/check`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}
