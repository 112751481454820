<template>
  <span>
    <span>
      <component :is="resolveLayout">
        <router-view></router-view>
      </component>
    </span>
  </span>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import getEnv from './services/env.js'
import io from 'socket.io-client'

const AUTH_API_BASE_URL = getEnv('VUE_APP_API_BASE_URL')
  ? getEnv('VUE_APP_API_BASE_URL')
  : 'http://localhost:3000/admin'

export default {
  components: {
    LayoutBlank,
    LayoutContent,
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      if (route.value.name === null) return null
      return route.value.meta.layout === 'blank' ? 'layout-blank' : 'layout-content'
    })

    return {
      resolveLayout,
    }
  },
  data() {
    return {
      socket: null,
      socketioURL: AUTH_API_BASE_URL,
    }
  },
  async mounted() {
    this.initializeSocket()
  },
  methods: {
    initializeSocket() {
      // Connect Socket
      this.socket = io(this.socketioURL, { transports: ['websocket'] })
      const token = this.$store.getters.getToken
      if (token) {
        this.socket.emit('connection', token)
      }

      this.socket.on('connect', () => {
        // console.log('[socket connected]: ', this.socket.connected)
      })
      this.socket.on('warning', data => {
        data.message = this.$t(`warning_list.${data.action}`)
        // console.log('[data]: ', data)
        this.handleLogout(data)
      })
      this.socket.on('disconnect', reason => {
        // console.log('[socket disconnected]: ', reason)
      })
      this.socket.on('connect_error', error => {
        // console.error('[connect error]: ', error)
      })
    },
    reinitializeSocket() {
      if (this.socket) {
        this.socket.disconnect()
      }
      this.initializeSocket()
    },
    handleLogout(data) {
      this.$store.dispatch('logout', data)
    },
  },
  computed: {
    isLoading() {
      return this.$store.state.loader.loading
    },
    token() {
      return this.$store.getters.getToken
    },
  },
  watch: {
    token(newToken, oldToken) {
      if (newToken !== oldToken) {
        this.reinitializeSocket()
      }
    },
    resolveLayout: function (val) {
      if (val === 'layout-content') {
      }
    },
  },
  beforeDestroy() {
    if (this.socket) {
      this.socket.disconnect()
    }
  },
}
</script>

<style scoped>
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: 2;
  cursor: pointer;
  pointer-events: none;
}
.disable-page {
  pointer-events: none;
}
</style>
