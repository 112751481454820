import moment from 'moment-timezone';

const defaultTz = 'Asia/Bangkok';

export function getFormattedTime(time, tz, formatDate) {
  time = time || new Date()
  tz = tz || defaultTz
  formatDate = formatDate || 'YYYY-MM-DD HH:mm:ss'
  return moment(time).tz(tz).format(formatDate);
}

export function getFormattedOffset(time, tz) {
  const offsetMinutes = moment(time).tz(tz).utcOffset();
  const hours = Math.abs(offsetMinutes) / 60;
  const sign = offsetMinutes >= 0 ? '+' : '-';
  return `GMT${sign}${hours}`;
}


export function getDateFrom(time, tz, formatDate) {
  time = time || new Date()
  tz = tz || defaultTz
  formatDate = formatDate || 'YYYY-MM-DD HH:mm:ss'
  return moment(time).tz(tz).format(formatDate);
}
