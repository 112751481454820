import { AUTH_API_BASE_URL, urlFor, request } from '../index'

export async function getActivityBanner(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/activity-banner?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function updateActivityBanner(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/activity-banner/update`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}

export async function addActivityBanner(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/activity-banner/add`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}

export async function removeActivityBanner(id) {
  try {
    return request('delete', urlFor(AUTH_API_BASE_URL, `/activity_banner?id=` + id), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}


