import * as MemberService from '@/services/apis/member'
const state = {}

const actions = {
  async getMember({ }, params) {
    try {
      let text = ''
      for (let i in params) {
        text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
      }
      const res = await MemberService.getMember(text)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async deleteMember({ }, id) {
    try {
      const res = await MemberService.deleteMember(id)
      if (res.status === 204) {
        return {}
        // await dispatch('permissions');
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
      // return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async createGame({ }, id) {
    try {
      const res = await MemberService.createGame(id)
      if (res.status === 200 || res.status === 201) {
        return {}
        // await dispatch('permissions');
      } else {
        throw Error(JSON.stringify({ message: res.data.message, err: res.data.err }))
      }
      // return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },

  async getPartner({ }, params) {
    try {
      let text = ''
      for (let i in params) {
        text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
      }
      const res = await MemberService.getPartner(text)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async getPartnerRef({ }, params) {
    try {
      let text = ''
      let id = 0
      for (let i in params) {
        if (i === 'id') {
          id = params[i]
        } else {
          text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
        }
      }
      const res = await MemberService.getPartnerRef(id, text)
      if (res.status === 201 || res.status === 200) {
        return res.data
        // await dispatch('permissions');
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async getPartnerCommission({ }, params) {
    try {
      let text = ''
      let id = 0
      for (let i in params) {
        if (i === 'id') {
          id = params[i]
        } else {
          text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
        }
      }
      const res = await MemberService.getPartnerCommission(id, text)
      if (res.status === 201 || res.status === 200) {
        return res.data
        // await dispatch('permissions');
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async getPartnerRefMemberReport({ }, params) {
    try {
      let text = ''
      let id = 0
      for (let i in params) {
        if (i === 'id') {
          id = params[i]
        } else {
          text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
        }
      }
      const res = await MemberService.getPartnerRefMemberReport(id, text)
      if (res.status === 201 || res.status === 200) {
        return res.data
        // await dispatch('permissions');
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },

  async getMemberWallet({ }, id) {
    try {
      const res = await MemberService.getMemberWallet(id)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async getMemberDepositWithdraw({ }, id) {
    try {
      const res = await MemberService.getMemberDepositWithdraw(id)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async getMemberTurnOver({ }, id) {
    try {
      const res = await MemberService.getMemberTurnOver(id)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async ClearTurnOver({ }, data) {
    try {
      const res = await MemberService.ClearTurnOver(data)
      if (res.status === 201 || res.status === 200) {
        return {}
        // await dispatch('permissions');
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async customClearTurnOver({ }, params) {
    try {
      const res = await MemberService.customClearTurnOver(params.id, params.data)
      if (res.status === 201 || res.status === 200) {
        return {}
        // await dispatch('permissions');
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if (err.message) { throw JSON.parse(err.message) }
    }
  },
  async customUpdateTurnOver({ }, params) {
    try {
      const res = await MemberService.customUpdateTurnOver(params.id, params.data)
      if (res.status === 201 || res.status === 200) {
        return {}
        // await dispatch('permissions');
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if (err.message) { throw JSON.parse(err.message) }
    }
  },

  async getMemberAffiliate({ }, params) {
    try {
      let text = ''
      for (let i in params) {
        text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
      }
      const res = await MemberService.getMemberAffiliate(text)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async updateMember({ }, data) {
    try {
      const res = await MemberService.updateMember(data)
      if (res.status === 201 || res.status === 200) {
        return {}
        // await dispatch('permissions');
      } else {
        throw Error(JSON.stringify({ message: res.data.message }))
      }
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async getPartnerour({ }, data) {
    try {
      const res = await MemberService.getPartnerour(data)
      return res
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
}

export default {
  state,
  actions,
}
