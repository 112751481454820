import { AUTH_API_BASE_URL, urlFor, request } from '../index';

export async function register2fa(params) {
  try {
      let res = request('post', urlFor(AUTH_API_BASE_URL, '/register2fa'), {
          data: params,
          auth: true
      });
      return res
  } catch (e) {
      return e
  }
}

export async function login2fa(params) {
  try {
      let res = request('post', urlFor(AUTH_API_BASE_URL, '/login2fa'), {
          data: params
      });
      return res
  } catch (e) {
      return e
  }
}

export async function generate2fa(params) {
  try {
      let res = request('post', urlFor(AUTH_API_BASE_URL, '/generate2fa'), {
          data: params,
          auth: true
      });
      return res
  } catch (e) {
      return e
  }
}

export async function verify2fa(params) {
  try {
      let res = request('post', urlFor(AUTH_API_BASE_URL, '/verify2fa'), {
          data: params,
          auth: true
      });
      return res
  } catch (e) {
      return e
  }
}

export async function deleteGenerate2fa(params) {
  try {
      let res = request('post', urlFor(AUTH_API_BASE_URL, '/delete-generate2fa'), {
          data: params,
          auth: true
      });
      return res
  } catch (e) {
      return e
  }
}

export async function delete2fa(params) {
  try {
      let res = request('post', urlFor(AUTH_API_BASE_URL, '/delete2fa'), {
        data: params,
        auth: true
      });
      return res
  } catch (e) {
      return e
  }
}
