import * as PaykrubTransferOutService from '@/services/apis/paykrubTransferOut'
const state = {}

const actions = {
  // async getSpadeHighData({ }, params) {
  //   try {
  //     const res = await PaykrubTransferOutService.getSpadeHighData(params)
  //     return res.data
  //   } catch (err) {
  //     if (err.message) {
  //       throw JSON.parse(err.message)
  //     }
  //   }
  // },

  // async saveSpadeHighData({ }, params) {
  //   try {
  //     const res = await PaykrubTransferOutService.saveSpadeHighData(params)
  //     return res.data
  //   } catch (err) {
  //     if (err.message) {
  //       throw JSON.parse(err.message)
  //     }
  //   }
  // },
}

export default {
  state,
  actions,
}
