import { AUTH_API_BASE_URL, urlFor, request } from '../index'

export async function dashboard() {
  try {
    let res = request('get', urlFor(AUTH_API_BASE_URL, '/auth/login'), {
      auth: true,
    })
    return res
  } catch (e) {
    return e
  }
}

export async function dashboard2(params) {
  try {
    let res = request('post', urlFor(AUTH_API_BASE_URL, '/auth/login'), {
      auth: true,
      data: params,
    })
    return res
  } catch (e) {
    return e
  }
}

export async function dashboardToday() {
  try {
    let res = request('get', urlFor(AUTH_API_BASE_URL, '/dashboard/today'), {
      auth: true,
    })
    return res
  } catch (e) {
    return e
  }
}

export async function dashboardMemberInfo() {
  try {
    let res = request('get', urlFor(AUTH_API_BASE_URL, '/dashboard/member/info'), {
      auth: true,
    })
    return res
  } catch (e) {
    return e
  }
}
export async function dashboardAccountbank() {
  try {
    let res = request('get', urlFor(AUTH_API_BASE_URL, '/dashboard/accountbank'), {
      auth: true,
    })
    return res
  } catch (e) {
    return e
  }
}

export async function dashboardDeposit() {
  try {
    let res = request('get', urlFor(AUTH_API_BASE_URL, '/dashboard/deposit'), {
      auth: true,
    })
    return res
  } catch (e) {
    return e
  }
}

export async function dashboardWithdraw() {
  try {
    let res = request('get', urlFor(AUTH_API_BASE_URL, '/dashboard/withdraw'), {
      auth: true,
    })
    return res
  } catch (e) {
    return e
  }
}

export async function dashboardDaily() {
  try {
    let res = request('get', urlFor(AUTH_API_BASE_URL, '/dashboard/daily'), {
      auth: true,
    })
    return res
  } catch (e) {
    return e
  }
}

export async function dashboardMonthly() {
  try {
    let res = request('get', urlFor(AUTH_API_BASE_URL, '/dashboard/monthly'), {
      auth: true,
    })
    return res
  } catch (e) {
    return e
  }
}

export async function dashboardMonthlyV2() {
  try {
    let res = request('get', urlFor(AUTH_API_BASE_URL, '/dashboard/v2/monthly'), {
      auth: true,
    })
    return res
  } catch (e) {
    return e
  }
}

export async function dashboardAffiliateTotalPlay(query) {
  try {
    let url = ''
    if (query && query.id) {
      url = `/affiliate/total-play?id=${query.id}&time_from=${query.time_from}&time_to=${query.time_to}`;
    } else {
      url = `/affiliate/total-play`;
    }
    let res = request('get', urlFor(AUTH_API_BASE_URL, url), {
      auth: true,
    })
    return res
  } catch (e) {
    return e
  }
}
