import { AUTH_API_BASE_URL, urlFor, request } from '../index';

export async function getDeposit(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/deposit?${params}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function addDeposit(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, '/deposit/add'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}

export async function updateDeposit(data) {
  try {
    return request('patch', urlFor(AUTH_API_BASE_URL, '/deposit/update'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}

export async function getTransfer(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/transfer-money?${params}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function addTransfer(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/transfer-money/add`), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}

export async function addTransferOut(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/transfer-money/add-transfer-out`), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}

export async function updateTransfer(data) {
  try {
    return request('patch', urlFor(AUTH_API_BASE_URL, `/transfer-money/update`), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}


export async function getWithdraw(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/withdraw?${params}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function addWithdraw(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, '/withdraw/add'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}

export async function updateWithdraw(data) {
  try {
    return request('patch', urlFor(AUTH_API_BASE_URL, '/withdraw/update'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}

export async function updateWithdrawTrueWallet(data) {
  try {
    return request('patch', urlFor(AUTH_API_BASE_URL, '/withdraw/update-truewallet'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}

export async function updateWithdrawPaykrub(data) {
  try {
    return request('patch', urlFor(AUTH_API_BASE_URL, '/withdraw/update-paykrub'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}

export async function updateWithdrawCloudpay(data) {
  try {
    return request('patch', urlFor(AUTH_API_BASE_URL, '/withdraw/update-cloudpay'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}

export async function updateWithdrawJompay(data) {
  try {
    return request('patch', urlFor(AUTH_API_BASE_URL, '/withdraw/update-jompay'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}

export async function updateWithdrawHypay(data) {
  try {
    return request('patch', urlFor(AUTH_API_BASE_URL, '/withdraw/update-hypay'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}

export async function getAccountBankDeposit() {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/deposit/accountbank`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function getStampDeposit(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/stamp-deposit?${params}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function addDepositGames(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, '/deposit/add-by-user'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}

export async function cancelDepositGames(data) {
  try {
    return request('delete', urlFor(AUTH_API_BASE_URL, '/stamp-deposit/cancel'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}
