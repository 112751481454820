import * as MessageBoardService from '@/services/apis/messageBoard'
const state = {
}

const actions = {
  async getMessageBoard({ }, params) {
    try {
      let text = ''
      for (let i in params) {
        text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
      }
      const res = await MessageBoardService.getMessageBoard(text)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async uppdateMessageBoard({ }, data) {
    try {
      const res = await MessageBoardService.uppdateMessageBoard(data)
      if (res.status === 201) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async deleteMessageBoard({ }, id) {
    try {
      const res = await MessageBoardService.deleteMessageBoard(id)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
}

export default {
  state,
  actions,
}
