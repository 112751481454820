import { AUTH_API_BASE_URL, urlFor, request } from '../index';

export async function getAffiliate(text) {
    try {
        return request('get', urlFor(AUTH_API_BASE_URL, `/affiliate?${text}`), {
            auth: true
        });
    } catch (e) {
        return e
    }
}

export async function getAffiliateTotal(params) {
    try {
        return request('get', urlFor(AUTH_API_BASE_URL, `/affiliate/total?time_from=${params.time_from}&time_to=${params.time_to}`), {
            auth: true
        });
    } catch (e) {
        return e
    }
}

export async function getAffiliateLastPlay(params) {
  try {
        return request('get', urlFor(AUTH_API_BASE_URL, `/affiliate/last-play?${params}`), {
            auth: true
        });
  } catch (e) {
        return e
  }
}
