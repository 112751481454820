const state = {
    loading: false
}
const getters = {
    getIsLoad: (state) => {
      return state.loading
    },
  }
const mutations = {
    setLoading: (state,value) => {
      state.loading = value
    },
  }

const actions = {
    startLoad: ({ commit }) => {
        commit('setLoading', true)
      },
      finishLoad: ({ commit }) => {
        commit('setLoading', false)
      },
}
export default {
    state,
    mutations,
    getters,
    actions
  }