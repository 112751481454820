<template>
  <v-menu offset-y left nudge-bottom="14" min-width="230" content-class="user-profile-menu-content">
    <template v-slot:activator="{ on, attrs }">
      <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
        <v-avatar size="50px" v-bind="attrs" v-on="on">
          <v-icon size="40px" color="primary">mdi-account</v-icon>
        </v-avatar>
      </v-badge>
    </template>
    <v-list>
      <div class="pb-3 pt-2">
        <v-badge bottom color="success" overlap offset-x="12" offset-y="12" class="ms-4" dot>
          <v-avatar size="40px">
            <v-icon color="primary">mdi-account</v-icon>
          </v-avatar>
        </v-badge>
        <div class="d-inline-flex flex-column justify-center ms-3" style="vertical-align: middle">
          <span class="text--primary font-weight-semibold mb-n1"> {{ userInfo.name }} </span>
          <small class="text--disabled text-capitalize">{{ userInfo.role }} </small>
        </div>
      </div>

      <v-divider></v-divider>

      <!-- Logout -->
      <v-list-item link @click="logout">
        <v-list-item-icon class="me-2">
          <v-icon size="30">
            <v-icon>mdi-logout</v-icon>
          </v-icon>
        </v-list-item-icon>
        <v-list-item-content>
          <v-list-item-title>Logout</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  setup() {
    return {}
  },
   data() {
    return {
      userInfo: {
        name: null,
        role: null,
      },
    }
  },
  async mounted() {
    let userInfo = this.$store.getters.getuserInfo
    this.userInfo.name = userInfo.name
    this.userInfo.role = userInfo.role
  },
  methods: {
    async logout() {
      await this.$store.dispatch('logout')
    },
  },
}
</script>

<style lang="scss">
.user-profile-menu-content {
  .v-list-item {
    min-height: 2.5rem !important;
  }
}
</style>
