import { AUTH_API_BASE_URL, urlFor, request } from '../index';

export async function getAnnounce() {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/setting-web/update/getannounce`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function addAnnounce(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, '/setting-web/update/addannounce'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}

export async function deleteAnnounce(id) {
  try {
    return request('delete', urlFor(AUTH_API_BASE_URL, `/setting-web/update/deleteannounce?id=${id}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}
