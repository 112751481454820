
<template>
  <v-navigation-drawer :value="isDrawerOpen" app floating
    :class="isLoading ? 'app-navigation-menu disable-bar' : 'app-navigation-menu'" :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)">

    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link to="/" class="d-flex align-center text-decoration-none">
        <v-icon class="logoConner" size="30">mdi-cogs</v-icon>
        &nbsp; &nbsp;
        <v-slide-x-transition>
          <h2 class="app-title logoConner">{{ webname }}</h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand class="vertical-nav-menu-items pr-5">
      <div v-for="(menu, index) in menus" :key="index">
        <nav-menu-section-title v-if="menu.titleName && menu.menus.length > 0"
          :title="menu.titleName"></nav-menu-section-title>
        <v-list-item active-class="active-nav-bar" v-for="mainMenu in menu.menus" :key="mainMenu.title"
          :to="{ name: mainMenu.to }">
          <v-list-item-icon>
            <v-icon>{{ mainMenu.icon }}</v-icon>
          </v-list-item-icon>
          <v-list-item-title>{{ mainMenu.title }}</v-list-item-title>
        </v-list-item>
      </div>
      <v-list-item class="list-dummy">
        <v-list-item-title></v-list-item-title>
      </v-list-item>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import i18n from '@/i18n'

export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  data: () => ({
    menus: [
      {
        titleName: null,
        menus: [{ key: 'dashboard', title: i18n.t("navigator_menu.dashboard"), to: 'dashboard', icon: 'mdi-home-outline' }],
      },
      {
        titleName: i18n.t("navigator_menu.member_head"),
        menus: [
          { key: 'staff', title: i18n.t("navigator_menu.member_employee"), to: 'staff', icon: 'mdi-account-multiple-outline' },
          { key: 'user', title: i18n.t("navigator_menu.member_member"), to: 'user', icon: 'mdi-account-multiple-outline' },
          { key: 'userLastPlay', title: i18n.t("navigator_menu.member_last_play_member"), to: 'userLastPlay', icon: 'mdi-account-multiple-outline' },
          { key: 'agent', title: i18n.t("navigator_menu.member_partner"), to: 'agent', icon: 'mdi-account-multiple-outline' },
          { key: 'ref', title: i18n.t("navigator_menu.member_suggestion"), to: 'ref', icon: 'mdi-email-outline' },
          { key: 'bonus', title: i18n.t("navigator_menu.member_bonus_suggestion"), to: 'ref-bonus', icon: 'mdi-email-outline' },
          { key: 'returnbalance', title: i18n.t("navigator_menu.member_bonus_cashback"), to: 'ref-returnbalance', icon: 'mdi-email-outline' },
          { key: 'coupon', title: i18n.t("navigator_menu.member_create_coupon"), to: 'coupon', icon: 'mdi-email-outline' },
          { key: 'pointCoinExp', title: 'Point - Coin - Exp', to: 'point-coin-exp', icon: 'mdi-trophy' },
        ],
      },
      {
        titleName: i18n.t("navigator_menu.transaction_head"),
        menus: [
          { key: 'deposit', title: i18n.t("navigator_menu.transaction_credit_management"), to: 'deposit', icon: 'mdi-currency-usd' },
          { key: 'creditwait', title: i18n.t("navigator_menu.transaction_deposit_wait_unpaid"), to: 'creditwait', icon: 'mdi-plus' },
          { key: 'credit', title: i18n.t("navigator_menu.transaction_deposit_wait"), to: 'credit', icon: 'mdi-plus' },
          { key: 'credit', title: i18n.t("navigator_menu.transaction_deposit_wait_member"), to: 'user-deposit', icon: 'mdi-plus' },
          { key: 'withdraw', title: i18n.t("navigator_menu.transaction_transaction_withdraw"), to: 'withdraw', icon: 'mdi-minus' },
          { key: 'transferOut', title: i18n.t("navigator_menu.transaction_transfer"), to: 'TransferOut', icon: 'mdi-cash' },
          { key: 'paykrubTransferOut', title: i18n.t("navigator_menu.paykrub_transaction_transfer"), to: 'paykrubTransferOut', icon: 'mdi-cash' },
        ],
      },
      {
        titleName: i18n.t("navigator_menu.account_head"),
        menus: [
          { key: 'accountingDepositReport', title: i18n.t("navigator_menu.account_report_deposit"), to: 'accounting-deposit-report', icon: 'mdi-file-document-box-outline' },
          { key: 'accountingWithdrawReport', title: i18n.t("navigator_menu.account_report_withdraw"), to: 'accounting-withdraw-report', icon: 'mdi-file-document-box-outline' },

        ],
      },
      {
        titleName: i18n.t("navigator_menu.account_report_head"),
        menus: [
          { key: 'logTransferOut', title: i18n.t("navigator_menu.account_deposit_withdraw_summary"), to: 'report-deposit-and-withdraw', icon: 'mdi-chart-bar' },
          { key: 'reportBonus', title: i18n.t("navigator_menu.account_bonus"), to: 'bonus-summary', icon: 'mdi-email-outline' },
        ],
      },
      {
        titleName: i18n.t("navigator_menu.log_head"),
        menus: [
          { key: 'logDepositWithdraw', title: i18n.t("navigator_menu.log_deposit_withdraw"), to: 'log-deposit-withdraw', icon: 'mdi-bank-transfer' },
          { key: 'logAccount', title: i18n.t("navigator_menu.log_member"), to: 'log-account', icon: 'mdi-account-multiple-outline' },
          { key: 'logSms', title: i18n.t("navigator_menu.log_sms"), to: 'log-sms', icon: 'mdi-message-processing' },
          { key: 'logPage', title: i18n.t("navigator_menu.log_web_open"), to: 'log-page', icon: 'mdi-open-in-app' },
          { key: 'logExport', title: i18n.t("navigator_menu.log_export_data"), to: 'log-export', icon: 'mdi-file-export-outline' },
          { key: 'logLineNotify', title: i18n.t("navigator_menu.log_line_noti"), to: 'log-line-notify', icon: 'mdi-bell-outline' },
          { key: 'logWheel', title: i18n.t("navigator_menu.log_wheel"), to: 'log-wheel', icon: 'mdi-ferris-wheel' },
          { key: 'logCoupon', title: i18n.t("navigator_menu.log_coupon"), to: 'log-coupon', icon: 'mdi-ticket-confirmation-outline' },
          { key: 'logPointCoin', title: i18n.t("navigator_menu.log_point_coin"), to: 'log-point-coin', icon: 'mdi-circle-multiple' },
          { key: 'logBank', title: i18n.t("navigator_menu.log_bank"), to: 'log-bank', icon: 'mdi-bank' },
          { key: 'logCashbackSetting', title: i18n.t("navigator_menu.log_close_open_bonus_cashback"), to: 'log-cashback-setting', icon: 'mdi-cash-sync' },
        ],
      },
      {
        titleName: i18n.t("navigator_menu.activity_head"),
        menus: [
          { key: 'dailyLogin', title: i18n.t("navigator_menu.activity_daily_login"), to: 'daily-login', icon: 'mdi-file-document-box-outline' },
          { key: 'luckyCard', title: i18n.t("navigator_menu.activity_lucky_card"), to: 'lucky-card', icon: 'mdi-file-document-box-outline' },
          { key: 'predictLotto', title: i18n.t("navigator_menu.activity_lotto"), to: 'predict-lotto', icon: 'mdi-file-document-box-outline' },
          { key: 'predictFootball', title: i18n.t("navigator_menu.activity_ball"), to: 'predict-football', icon: 'mdi-file-document-box-outline' },
          { key: 'spadeHigh', title: i18n.t("navigator_menu.activity_spade_high"), to: 'spadehigh', icon: 'mdi-file-document-box-outline' },
          { key: 'mineSweeper', title: i18n.t("navigator_menu.activity_mine_sweeper"), to: 'minesweeper', icon: 'mdi-file-document-box-outline' },
          { key: 'redeemReward', title: i18n.t("navigator_menu.activity_redeem_reward"), to: 'redeemreward', icon: 'mdi-file-document-box-outline' },
          { key: 'rankingReward', title: i18n.t("navigator_menu.activity_ranking_reward"), to: 'rankingreward', icon: 'mdi-file-document-box-outline' },
        ],
      },
      {
        titleName: i18n.t("navigator_menu.config_head"),
        menus: [
          { key: 'promotion', title: i18n.t("navigator_menu.config_promotion"), to: 'promotion', icon: 'mdi-checkbox-multiple-blank-outline' },
          { key: 'news', title: i18n.t("navigator_menu.config_news"), to: 'news', icon: 'mdi-card-text-outline' },
          { key: 'announce', title: i18n.t("navigator_menu.config_announce"), to: 'announce', icon: 'mdi-card-text-outline' },
          { key: 'messageBoard', title: i18n.t("navigator_menu.config_message_baord"), to: 'messageBoard', icon: 'mdi-card-text-outline' },
          { key: 'activityBanner', title: i18n.t("navigator_menu.config_activity_banner"), to: 'activitybanner', icon: 'mdi-trophy' },
          { key: 'video', title: i18n.t("navigator_menu.config_video"), to: 'video', icon: 'mdi-checkbox-multiple-blank-outline' },
          { key: 'bonusExp', title: i18n.t("navigator_menu.config_bonus_exp"), to: 'bonus-exp', icon: 'mdi-trophy' },
          { key: 'settingWebsite', title: i18n.t("navigator_menu.config_web"), to: 'web_setting', icon: 'mdi-settings' },
          { key: 'settingBank', title: i18n.t("navigator_menu.config_bank"), to: 'bank', icon: 'mdi-checkbox-multiple-blank-outline' },
        ],
      },
    ],
    webname: null
  }),
  async mounted() {
    this.webname = await this.$store.getters.getWebName
  },
  async created() {
    let isShowTransferOut = false;
    let isShowCoupon = false;
    let isShowVideo = false;
    await this.$store.dispatch('getSettingFeature').then(res => {
      const chkShowTransferOut = res.filter((item) => item.page === 'transferOut')[0]?.value === 1 ? true : false;
      isShowTransferOut = chkShowTransferOut;

      const chkShowCoupon = res.filter((item) => item.page === 'coupon')[0]?.value === 1 ? true : false;
      isShowCoupon = chkShowCoupon;

      const chkShowVideo = res.filter((item) => item.page === 'video')[0]?.value === 1 ? true : false;
      isShowVideo = chkShowVideo;
    })
    let permissions = this.$store.getters.getPermissions
    let newMenus = []

    this.menus.forEach(mElement => {
      let buildMenu = { titleName: mElement.titleName, menus: [] }
      mElement.menus.forEach(cElement => {
        if (permissions.includes(cElement.key)) {
          if ((cElement.key === 'transferOut' && !isShowTransferOut) || (cElement.key === 'coupon' && !isShowCoupon) || (cElement.key === 'video' && !isShowVideo)) {
          } else {
            buildMenu.menus.push(cElement)
          }
        }
      })
      newMenus.push(buildMenu)
    })
    this.menus = newMenus
  },
  setup() {
    return {}
  },

  computed: {
    isLoading() {
      return this.$store.state.loader.loading
    }
  }
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;

  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  // background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  width: 260px !important;

  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}

.app-navigation-menu .v-list-item {
  transition: background 0.3s, transform 0.3s;
  border-radius: 0px 32px 32px 0px;
  transition: 0.25s cubic-bezier(0.25, 0.8, 0.5, 1);
}

.v-list-item--link:before {
  border-radius: 0px 32px 32px 0px;
}

.active-nav-bar {
  color: white;
  // background-color: #7367f0;
  background: linear-gradient(to bottom, #8f86ef, #7367f0, #6557dc);
  box-shadow: rgba(132, 107, 233, 0.267) 0px 0px 16px 8px;
  border-radius: 0px 32px 32px 0px;
}

.logoConner {
  color: #7367f0;
}

.disable-bar {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  // background-color: rgba(133, 76, 76, 0.1);
  z-index: 2;
  cursor: pointer;
  pointer-events: none;
  overflow: hidden;
}

.list-dummy {
  display: none !important;
}

@media (max-width: 550px) {
  .app-navigation-menu {
    width: 200px !important;
    height: 100vh !important;
  }

  .app-navigation-menu .v-list-item {
    padding-left: 15px;
    padding-right: 0 !important;
  }

  .list-dummy {
    display: block !important;
  }
}
</style>
