import * as SelectService from '@/services/apis/select'
const state = {
  admin: [],
  member: [],
  roles: [],
  promotion: [],
  bank: [],
  accountBank: [],
  bankKey: {
    BBL: "ธนาคารกรุงเทพ",
    KBANK: "ธนาคารกสิกรไทย",
    RBS: "ธนาคาร เดอะรอยัลแบงค์อ๊อฟสกอตแลนด์",
    KTB: "ธนาคารกรุงไทย",
    JPM: "JP Morgan Chase Bank",
    MUFG: "ธนาคารแห่งโตเกียว-มิตซูบิชิ ยูเอฟเจ",
    TMB: "ธนาคารทหารไทยธนชาต",
    SCB: "ธนาคารไทยพาณิชย์ (SCB)",
    CITI: "ธนาคารซิตี้แบงค์",
    SMBC: "ธนาคาร SMBC",
    SC: "Standard Chartered",
    CIMB: "ธนาคารซีไอเอ็มบีไทย",
    UOB: "ธนาคารยูโอบี",
    BAY: "ธนาคารกรุงศรีอยุธยา",
    MEGA: "ธนาคาร เมกะ สากลพาณิชย์",
    BOA: "Bank of America",
    CACIB: "Crédit Agricole Corporate and Investment Bank",
    GSB: "ธนาคารออมสิน",
    HSBC: "ธนาคารเอชเอสบีซี(HSBC)",
    DB: "ธนาคารดอยซ์ แบงก์",
    GHB: "ธนาคารอาคารสงเคราะห์",
    BAAC: "ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร",
    MB: "ธนาคาร MB",
    BNP: "ธนาคารบีเอ็นพี พารีบาส์",
    TBANK: "ธนาคารธนชาต",
    IBANK: "ธนาคารอิสลามแห่งประเทศไทย",
    TISCO: "ธนาคารทิสโก้",
    KK: "ธนาคารเกียรตินาคินภัทร จำกัด (มหาชน)",
    ICBC: "ธนาคารพาณิชย์อุตสาหกรรมแห่งประเทศจีน",
    TCRB: "ธนาคารไทยเครดิต เพื่อรายย่อย",
    LHB: "ธนาคารแลนด์ แอนด์ เฮ้าส์",
    TTB: "ธนาคารทหารไทยธนชาต",
    TRUEWALLET: "ทรูวอลเลท",
  }
}

const getters = {
  getAdmin: (state) => {
    return state.admin
  },
  getRoles: (state) => {
    return state.roles
  },
  getMember: (state) => {
    return state.member
  },
  getPromotion: (state) => {
    return state.promotion
  },
  getBank: (state) => {
    return state.bank
  },
  getAccountBank: (state) => {
    return state.accountBank
  },
  getKeyBank: (state) => {
    return state.bankKey
  }
}

const mutations = {
  setAdmin: (state, value) => {
    state.admin = value
  },
  setRoles: (state, value) => {
    state.roles = value
  },
  setMember: (state, value) => {
    state.admin = value
  },
  setPromotion: (state, value) => {
    state.promotion = value
  },
  setBank: (state, payload) => {
    state.bank = payload
  },
  setAccountBank: (state, payload) => {
    state.accountBank = payload
  },
}

const actions = {
  async getAdminData({ commit }) {
    try {
      const res = await SelectService.getAdmin()
      commit('setAdmin', res.data)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getMemberData({ commit }) {
    try {
      const res = await SelectService.getMember()
      commit('setMember', res.data)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getRoleData({ commit }) {
    try {
      const res = await SelectService.getRole()
      commit('setAdmin', res.data)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getPromotionData({ commit }) {
    try {
      const res = await SelectService.getPromotion()
      commit('setPromotion', res.data)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getTypeBankData({ commit }) {
    try {
      const res = await SelectService.getTypeBank()
      res.data.map(e => {
        if (state.bankKey[e.value]) {
          e["label"] = state.bankKey[e.value]
        }
      })
      commit('setBank', res.data)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getAccountBankData({ commit }) {
    try {
      const res = await SelectService.getAccountBank()
      commit('setAccountBank', res.data)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  }
}



export default {
  state,
  getters,
  actions,
  mutations
}