import { AUTH_API_BASE_URL, urlFor, request } from '../index'

export async function getPointCoinHistory(text) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/logger/point-coin?${text}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function updatePointCoin(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/point-coin/update-point-coin`), {
      auth: true,
      data: data
    })
  } catch (e) {
    return e
  }
}
