import { AUTH_API_BASE_URL, urlFor, request } from '../index'

export async function getLuckyCard(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/lucky-card-game/list`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function addLuckyCard(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/lucky-card-game/add`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}
