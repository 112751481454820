import * as NewsService from '@/services/apis/news'
const state = {
}

const actions = {
  async getNews({ }, params) {
    try {
      const res = await NewsService.getNews(params)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async addNews({ }, data) {
    try {
      const res = await NewsService.addNews(data)
      if (res.status === 201) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async updateNews({ }, data) {
    try {
      const res = await NewsService.updateNews(data)
      if (res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async deleteNews({ }, id) {
    try {
      const res = await NewsService.deleteNews(id)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
}

export default {
  state,
  actions,
}