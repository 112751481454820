import * as ActivityBannerService from '@/services/apis/activityBanner'
const state = {}

const actions = {
  async getActivityBanner({ }, params) {
    try {
      const res = await ActivityBannerService.getActivityBanner(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async updateActivityBanner({ }, data) {
    try {
      const res = await ActivityBannerService.updateActivityBanner(data)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async addActivityBanner({ }, data) {
    try {
      const res = await ActivityBannerService.addActivityBanner(data)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },

  async removeActivityBanner({ }, id) {
    try {
      const res = await ActivityBannerService.removeActivityBanner(id)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
}

export default {
  state,
  actions,
}
