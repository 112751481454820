import { AUTH_API_BASE_URL, urlFor, request } from '../index'

export async function getFootballList(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/predict-football/history?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function addFootball(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/predict-football/create`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}

export async function updateResultFootball(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/predict-football/update-result`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}

export async function removeFootball(data) {
  try {
    return request('delete', urlFor(AUTH_API_BASE_URL, `/predict-football/delete-predict-football-game?id=${data.id}`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}

export async function transferFootballRewardToMember(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/predict-football/transfers`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}

export async function getFootballGameUser(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/predict-football/user-bet-list?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getFootballGameUserReward(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/predict-football/user-reward-list?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}


