import * as DashboardService from '@/services/apis/dashboard'
const state = {
}

const actions = {
    async dashboardToday({ }) {
        try {
          const res = await DashboardService.dashboardToday()
          return res.data
        } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
        }
      },
      async dashboardMemberInfo({ }) {
        try {
          const res = await DashboardService.dashboardMemberInfo()
          return res.data
        } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
        }
      },
      async dashboardAccountbank({ }) {
        try {
          const res = await DashboardService.dashboardAccountbank()
          return res.data
        } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
        }
      },
      async dashboardDeposit({ }) {
        try {
          const res = await DashboardService.dashboardDeposit()
          return res.data
        } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
        }
      },
      async dashboardWithdraw({ }) {
        try {
          const res = await DashboardService.dashboardWithdraw()
          return res.data
        } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
        }
      },
      async dashboardDaily({ }) {
        try {
          const res = await DashboardService.dashboardDaily()
          return res.data
        } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
        }
      },
      async dashboardMonthly({ }) {
        try {
          const res = await DashboardService.dashboardMonthly()
          return res.data
        } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
        }
      },
      async dashboardMonthlyV2({ }) {
        try {
          const res = await DashboardService.dashboardMonthlyV2()
          return res.data
        } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
        }
      },
      async dashboardAffiliateTotalPlay({ }, params) {
        try {
          const res = await DashboardService.dashboardAffiliateTotalPlay(params)
          return res.data
        } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
        }
      },
}

export default {
    state,
    actions,
  }
