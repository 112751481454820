import { AUTH_API_BASE_URL, urlFor, request } from '../index'

export async function getBonusExp(text) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/bonus-exp?${text}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function addBonusExp(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/bonus-exp/add`), {
      auth: true,
      data: data
    })
  } catch (e) {
    return e
  }
}

export async function deleteBonusExp(id) {
  try {
    return request('delete', urlFor(AUTH_API_BASE_URL, `/bonus-exp/${id}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}
