import * as BonusService from '@/services/apis/bonus'
const state = {
}

const actions = {
    async getBonus({ }, params) {
        try {
            let text = ""
            for (let i in params) {
                text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
            }
            const res = await BonusService.getBonus(text)
            return res.data
        } catch (err) {
            if(err.message){ throw JSON.parse(err.message) }
        }
    },
    async getBonusTotal({ }) {
        try {
            const res = await BonusService.getBonusTotal()
            return res.data
        } catch (err) {
            if(err.message){ throw JSON.parse(err.message) }
        }
    },

}

export default {
    state,
    actions,
}