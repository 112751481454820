import { AUTH_API_BASE_URL, urlFor, request } from '../index'

export async function getLottoList(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/lotto-game/list?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function addLotto(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/lotto-game/create`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}

export async function updateResultLotto(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/lotto-game/update-result`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}

export async function removeLotto(data) {
  try {
    return request('delete', urlFor(AUTH_API_BASE_URL, `/lotto-game/delete-lotto-game?id=${data.id}`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}

export async function transferLottoRewardToMember(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/lotto-game/transfers`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}

export async function getLottoGameUser(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/lotto-game/user-bet-list?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getLottoGameUserReward(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/lotto-game/user-reward-list?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}


