import * as AccountingReportService from '@/services/apis/accountingReport'
const state = {
}

const actions = {
    async getAccountingRepositDeposit({ }, params) {
        try {
            const res = await AccountingReportService.getAccountingRepositDeposit(params)
            return res.data
        } catch (err) {
            if(err.message){ throw JSON.parse(err.message) }
        }
    },
    async getAccountingRepositWithdraw({ }, params) {
        try {
            const res = await AccountingReportService.getAccountingRepositWithdraw(params)
            return res.data
        } catch (err) {
            if(err.message){ throw JSON.parse(err.message) }
        }
    },
}


export default {
    state,
    actions,
}