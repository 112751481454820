import * as MangeRoleService from '@/services/apis/manageRole'
const state = {
}

const actions = {
  async getMangeRole({ }, params) {
    try {

      const res = await MangeRoleService.getManageRole(params)
      if (res.status === 200) {
        let temp = []
        res.data.data.forEach(element => {
          temp.push(element)
        });
        return temp
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
      // return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async addRole({ }, data) {
    try {
      const res = await MangeRoleService.addRole(data)
      if (res.status === 201) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async updateRole({ }, data) {
    try {
      const res = await MangeRoleService.updateRole(data)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async updatePermission({ }, data) {
    try {
      const res = await MangeRoleService.updatePermission(data)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async updatePermissionEdit({ }, data) {
    try {
      const res = await MangeRoleService.updatePermissionEdit(data)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async deleteRole({ }, id) {
    try {
      const res = await MangeRoleService.deleteRole(id)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
}

export default {
  state,
  actions,
}