import * as BonusExpService from '@/services/apis/bonusExp'
const state = {
}

const actions = {
    async getBonusExp({}, params) {
        try {
            let text = ""
            for (let i in params) {
                text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
            }
            const res = await BonusExpService.getBonusExp(text)
            return res.data
        } catch (err) {
            if(err.message){ throw JSON.parse(err.message) }
        }
    },
    async addBonusExp({}, params) {
        try {
            const res = await BonusExpService.addBonusExp(params)
            return res.data
        } catch (err) {
            if(err.message){ throw JSON.parse(err.message) }
        }
    },
    async deleteBonusExp({}, params) {
      try {
          const res = await BonusExpService.deleteBonusExp(params)
          return res.data
      } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
      }
  },
}

export default {
    state,
    actions,
}
