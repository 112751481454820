import { AUTH_API_BASE_URL, urlFor, request } from '../index';

export async function getNews(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/news?${params}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function addNews(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, '/news/add'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}

export async function updateNews(data) {
  try {
    return request('patch', urlFor(AUTH_API_BASE_URL, '/news/update'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}
export async function deleteNews(id) {
  try {
    return request('delete', urlFor(AUTH_API_BASE_URL, `/news/${id}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}