import * as PredictFootballService from '@/services/apis/predictFootball'
const state = {}

const actions = {
  async getFootballList({}, params) {
    try {
      const res = await PredictFootballService.getFootballList(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async addFootball({}, params) {
    try {
      const res = await PredictFootballService.addFootball(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async updateResultFootball({}, params) {
    try {
      const res = await PredictFootballService.updateResultFootball(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async removeFootball({}, params) {
    try {
      const res = await PredictFootballService.removeFootball(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async transferFootballRewardToMember({}, params) {
    try {
      const res = await PredictFootballService.transferFootballRewardToMember(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async getFootballGameUser({}, params) {
    try {
      const res = await PredictFootballService.getFootballGameUser(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async getFootballGameUserReward({}, params) {
    try {
      const res = await PredictFootballService.getFootballGameUserReward(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
}

export default {
  state,
  actions,
}
