import { AUTH_API_BASE_URL, urlFor, request } from '../index';

export async function getAdmin() {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, '/select/admin'), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function getMember() {
    try {
      return request('get', urlFor(AUTH_API_BASE_URL, '/select/member'), {
        auth: true
      });
    } catch (e) {
      return e
    }
  }

  export async function getRole() {
    try {
      return request('get', urlFor(AUTH_API_BASE_URL, '/select/role'), {
        auth: true
      });
    } catch (e) {
      return e
    }
  }
  
  export async function getPromotion() {
    try {
      return request('get', urlFor(AUTH_API_BASE_URL, '/select/promotion'), {
        auth: true
      });
    } catch (e) {
      return e
    }
  }
  
  export async function getTypeBank() {
    try {
      return request('get', urlFor(AUTH_API_BASE_URL, '/select/typebank'), {
        auth: true
      });
    } catch (e) {
      return e
    }
  }
  export async function getAccountBank() {
    try {
      return request('get', urlFor(AUTH_API_BASE_URL, '/select/accountbank'), {
        auth: true
      });
    } catch (e) {
      return e
    }
  }
  