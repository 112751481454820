import * as LoginService from '@/services/apis/login'
import * as settingsWebService from '@/services/apis/settingsWeb'
import Swal from 'sweetalert2'

const state = {
  token: null,
  permissions: [],
  permissionsEdit: [],
  userinfo: {
    name: null,
    role: null,
    auth2fa: 0,
  },
  webinfoname: null,
  //add state lineOtp/TelegramOtp/2fa
  authMethod: null,
  adminId: null,
  reference: null,
}

const getters = {
  getToken: state => {
    return state.token
  },
  getPermissions: state => {
    return state.permissions
  },
  getPermissionsEdit: state => {
    return state.permissionsEdit
  },
  getuserInfo: state => {
    return state.userinfo
  },
  getWebName: state => {
    return state.webinfoname
  },
  // Add to getters lineOtp/TelegramOtp/2fa
  getAuthMethod: state => state.authMethod,
  getAdminId: state => state.adminId,
  getReference: state => state.reference,
}

const mutations = {
  updateToken: (state, payload) => {
    state.token = payload
  },
  updatePermissions: (state, payload) => {
    state.permissions = payload
  },
  updatePermissionsEdit: (state, payload) => {
    state.permissionsEdit = payload
  },
  updateUserInfo: (state, payload) => {
    state.userinfo.name = payload.name
    state.userinfo.role = payload.role
    state.userinfo.auth2fa = payload.auth2fa
  },
  updateWebInfo: (state, payload) => {
    state.webinfoname = payload
  },
  resetAuth: (state, payload) => {
    state.token = null
    state.permissions = []
    state.userinfo.name = null
    state.userinfo.role = null
    state.userinfo.auth2fa = 0
  },
  // Add mutation lineOtp/TelegramOtp/2fa
  SET_AUTH_METHOD(state, method) {
    state.authMethod = method
  },
  SET_ADMIN_ID(state, id) {
    state.adminId = id 
  },
  SET_REFERENCE(state, ref) {
    state.reference = ref
  },
}

const actions = {
  async login({ commit, dispatch }, params) {
    try {

      let res = await LoginService.login(params)
      
      if (res.status === 201) {
        console.log('Login method:', res.data.method);
        switch (res.data.method) {
          case 'check_2fa':
            commit('SET_AUTH_METHOD', res.data.method)
            return res.data
          
          case 'check_line_otp':
            commit('SET_AUTH_METHOD', res.data.method)
            commit('SET_ADMIN_ID', res.data.admin_id)
            commit('SET_REFERENCE', res.data.reference)
            return res.data

          case 'check_telegram_otp':  // Add this case for telegramOtp
            commit('SET_AUTH_METHOD', res.data.method)
            commit('SET_ADMIN_ID', res.data.admin_id)
            commit('SET_REFERENCE', res.data.reference)
            return res.data
          
          default:
            // Normal login with access token
            if (res.data.access_token) {
              commit('updateToken', res.data.access_token)
              commit('SET_AUTH_METHOD', 'normal')
              return res.data
            }
            
            throw Error(JSON.stringify({ message: 'Login failed' }))
        }
      } else {
        throw new Error(res.data.message || 'Login failed');
      }
    } catch (err) {
      console.error('Login error details:', err);
      let errorMessage = 'Login failed';
      if (err.message) {
        try {
          // Try to parse as JSON
          errorMessage = JSON.parse(err.message).message;
        } catch (parseError) {
          // If parsing fails, use the original error message
          errorMessage = err.message;
        }
      }
      
      // If it's a network error or server error, provide a friendly message
      if (err.response && err.response.status) {
        if (err.response.status === 500) {
          errorMessage = 'Server error. Please contact the administrator.';
        } else if (err.response.status === 401) {
          errorMessage = 'Invalid credentials or authentication method not available.';
        }
      }
      
      throw { message: errorMessage };
    }
  },
  
  async permissions({ commit }) {
    try {
      let res = await LoginService.permissions()

      // mock up permission bonus-summary
      res.data.permissions.push({ id: 30, page: 'bonus-summary', path: '/bonus-summary', use: 1 })

      if (res.status === 200) {
        let temp = []
        let tempEdit = []
        res.data.permissions.forEach(element => {
          if (element.use) {
            temp.push(element.page)
          }
          if (element.edit) {
            tempEdit.push(element.page)
          }
        })
        commit('updatePermissions', temp)
        commit('updatePermissionsEdit', tempEdit)
        commit('updateUserInfo', res.data)
      } else {
        commit('updatePermissions', [])
        commit('updatePermissionsEdit', [])
        throw Error(JSON.stringify({ message: 'มีบางอย่างผิดพลาด' }))
      }
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },

  async login2fa({ commit, dispatch }, { username, password, pin }) {
    try {
      const res = await LoginService.login2fa(username, password, pin)
      
      if (res.status === 201 && res.data?.access_token) {
        commit('updateToken', res.data.access_token)
        commit('SET_AUTH_METHOD', 'normal')
        await dispatch('permissions')
        return res.data
      }
      
      throw new Error(res.data?.message || 'Two-factor authentication failed')
    } catch (err) {
      console.error('2FA Login Error:', err)
      
      if (err.response) {
        throw new Error(err.response.data?.message || 'Two-factor authentication failed')
      } else if (err.request) {
        throw new Error('No response received from server')
      } else {
        throw new Error(err.message || 'An unexpected error occurred')
      }
    }
  },
  async webSettingInfo({ commit }) {
    try {
      let result = await settingsWebService.getWebInfo()
      commit('updateWebInfo', result.data.name)
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async logout({ commit }, param) {
    if (param && param.type === 'warning') {
      Swal.fire({
        icon: 'error',
        title: param.message,
      }).then(action => {
        commit('resetAuth')
        location.reload()
      })
    } else {
      commit('resetAuth')
      location.reload()
    }
  },
}

export default {
  state,
  mutations,
  getters,
  actions,
}
