<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar app flat absolute elevate-on-scroll
      style="position: fixed; background: transparent; backdrop-filter: blur(50px); z-index: 12" color="red">
      <div class="boxed-container w-full" style="background: transparent">
        <div class="d-flex align-center px-4" style="
            margin: 0 32px;
            background: #ffffff;
            border-radius: calc(2rem - 8px);
            box-shadow: rgba(10, 10, 10, 0.075) 0px 0px 16px 8px;
          ">
          <!-- Left Content -->
          <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>
          <div class="d-flex align-center justify-center">
            <audio v-bind:src="nameSound" ref="audio" volumn="0" id="alert-sound" preload="auto" controls="controls"
              style="height: 30px; width: 210px; margin-right: 10px"></audio>

            <button @click="switchLocale()" class="mt-1">
              <img v-if="this.$i18n.locale == 'en'" src="../assets/images/flags/EN.webp"
                style="width: 100%; max-width: 28px; object-fit: contain" />
              <img v-if="this.$i18n.locale == 'th'" src="../assets/images/flags/TH.webp"
                style="width: 100%; max-width: 28px; object-fit: contain" />
            </button>

            <div class="pl-2" style="line-height:1;margin-top:-4px">
              <span style="font-size: min(4vw,0.75rem);">{{ formattedTime }} ({{ formattedOffset }})</span>
            </div>
          </div>
          <v-spacer></v-spacer>
          <select></select>

          <!-- <theme-switcher></theme-switcher> -->
          <div class="text-right">
            <span class="font-weight-bold">{{ userInfo.name }}</span> <br />
            <span class="caption">{{ userInfo.role }} </span>
          </div>
          <app-bar-user-menu :userInfo="userInfo"></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <!-- <v-sheet id="scrolling-techniques-7"   style="background-color: #f4f5fa" class="overflow-y-auto v-max-sheet"> -->
      <div class="app-content-container boxed-container app-content-p">
        <slot></slot>
      </div>
      <!-- </v-sheet> -->
    </v-main>

    <v-footer app inset color="red" absolute height="56" class="px-0">
      <div class="boxed-container w-full">
        <div class="mx-6 d-flex justify-space-between">
          <p>
            <span style="
                color: rgb(98, 98, 98);
                font-family: Kanit, sans-serif;
                font-size: 14px;
                font-style: normal;
                font-variant-ligatures: normal;
                font-variant-caps: normal;
                font-weight: 400;
                letter-spacing: 0.14px;
                orphans: 2;
                text-align: left;
                text-indent: 0px;
                text-transform: none;
                white-space: normal;
                widows: 2;
                word-spacing: 0px;
                -webkit-text-stroke-width: 0px;
                background-color: rgb(255, 255, 255);
                text-decoration-thickness: initial;
                text-decoration-style: initial;
                text-decoration-color: initial;
                display: inline !important;
                float: none;
              ">COPYRIGHT &copy; 2022</span><a class="text-bold-800 grey darken-2"
              href="https://1.envato.market/pixinvent_portfolio" style="
                box-sizing: border-box;
                color: rgb(115, 103, 240);
                text-decoration: none;
                background-color: rgb(255, 255, 255);
                margin: 0px 0.3rem;
                font-family: Kanit, sans-serif;
                font-size: 14px;
                font-style: normal;
                font-variant-ligatures: normal;
                font-variant-caps: normal;
                font-weight: 400;
                letter-spacing: 0.14px;
                orphans: 2;
                text-align: left;
                text-indent: 0px;
                text-transform: none;
                white-space: normal;
                widows: 2;
                word-spacing: 0px;
                -webkit-text-stroke-width: 0px;
              " target="_blank">GlobalSystem co., ltd.,</a><span style="
                color: rgb(98, 98, 98);
                font-family: Kanit, sans-serif;
                font-size: 14px;
                font-style: normal;
                font-variant-ligatures: normal;
                font-variant-caps: normal;
                font-weight: 400;
                letter-spacing: 0.14px;
                orphans: 2;
                text-align: left;
                text-indent: 0px;
                text-transform: none;
                white-space: normal;
                widows: 2;
                word-spacing: 0px;
                -webkit-text-stroke-width: 0px;
                background-color: rgb(255, 255, 255);
                text-decoration-thickness: initial;
                text-decoration-style: initial;
                text-decoration-color: initial;
                display: inline !important;
                float: none;
              ">All rights Reserved</span>
          </p>
        </div>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import ThemeSwitcher from './components/ThemeSwitcher.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import $ from 'jquery'
import moment from 'moment'
import { getFormattedTime, getFormattedOffset } from '@/utils/dateTimeUtils'
import { getCurrency } from '@/utils/currencyUtils'

export default {
  components: {
    VerticalNavMenu,
    ThemeSwitcher,
    AppBarUserMenu,
  },
  created() {
    let lang = localStorage.getItem('language')
    if (!lang) {
      localStorage.setItem('language', this.$i18n.locale)
    }
    this.$i18n.locale = lang

    this.getWithdraw()
    this.getInfoSetting()
    this.timeInterval()
    this.getWebSettingInfo()
  },
  async mounted() {
    let userInfo = this.$store.getters.getuserInfo
    this.userInfo.name = userInfo.name
    this.userInfo.role = userInfo.role
    let dataPlaySound = this.$store.dispatch('getPlaySound')
    dataPlaySound.then(res => (this.playSound = res))
  },
  setup() {
    const isDrawerOpen = ref(null)
    return {
      isDrawerOpen,
    }
  },

  data() {
    return {
      userInfo: {
        name: null,
        role: null,
      },
      nameSound: '/media/alert_sound1.1fae2578.mp3',
      playSound: null,
      itemsWithdrawData: [],
      dateFrom: moment().subtract(3, 'days').format('YY-MM-DD'),
      dateTo: moment().format('YY-MM-DD'),
      time: null,
      interval: null,
      bankSystem: '',
    }
  },

  methods: {
    playSoundAlert() {
      $('#alert-sound').trigger('play')
    },
    async getInfoSetting() {
      await this.$store.dispatch('getSettingWebInfo').then(res => {
        switch (res.alert_sound) {
          case 'alert_sound1.mp3':
            this.nameSound = '/media/alert_sound1.1fae2578.mp3'
            break
          case 'alert_sound2.mp3':
            this.nameSound = '/media/alert_sound2.6e822aef.mp3'
            break
          case 'alert_sound3.mp3':
            this.nameSound = '/media/alert_sound3.5d4bfc60.mp3'
            break
          case 'alert_sound4.mp3':
            this.nameSound = '/media/alert_sound4.88e24d69.mp3'
            break
          case 'alert_sound5.mp3':
            this.nameSound = '/media/alert_sound5.2b916413.mp3'
            break
          default:
            break
        }
      })
    },
    timeInterval() {
      this.tz = this.$store.getters.timezone
      this.updateTime();
      this.interval = setInterval(this.updateTime, 1000);
    },
    getWithdraw() {
      setInterval(async () => {
        try {
          let rs = await this.$store.dispatch('getWithdraw', {
            page: 1,
            rows: 30,
            time_from: this.formatDateYYYY(this.dateFrom),
            time_to: this.formatDateYYYY(this.dateTo),
          })
          let newWithdraw = []
          let oldWithdraw = []

          // compare new with old
          if (this.itemsWithdrawData.length > 0) {
            this.itemsWithdrawData.forEach(e => {
              oldWithdraw.push(e.id)
            })
          }

          rs.data.forEach(e => {
            if (oldWithdraw.length > 0 && !oldWithdraw.includes(e.id)) {
              this.playSoundAlert()
              const Toast = this.$swal.mixin({
                toast: true,
                position: 'top-end',
                showConfirmButton: false,
                timer: 5000,
                timerProgressBar: true,
                didOpen: toast => {
                  toast.addEventListener('mouseenter', this.$swal.stopTimer)
                  toast.addEventListener('mouseleave', this.$swal.resumeTimer)
                },
              })
              Toast.fire({
                icon: 'warning',
                title: `รายการถอนเงิน เบอร์ ${e.member ? e.member.phone : 'null'} จำนวน ${e.amount.toLocaleString(
                  'en-US',
                )} ${getCurrency()}`,
              })
            }

            newWithdraw.push({
              id: e.id,
              username: e.member.phone,
              amount: e.amount,
              bank: e.member.bank,
              bankAccount: e.member.bank_account,
              status: `${e.status === 'success' ? `${e.status}_${e.auto}` : e.status}`,
              date: moment(e.created_at).format('YY-MM-DD HH:mm'),
            })
          })
          this.itemsWithdrawData = []
          this.itemsWithdrawData = newWithdraw
        } catch (e) {
          if (
            !e.message.includes('Unexpected token') ||
            !e?.includes('Unexpected token') ||
            !e.message.includes('Unexpected identifier')
          ) {
            this.$swal.fire({
              icon: 'error',
              title: e.message,
            })
          }
        }
      }, 60000)
    },
    formatDateYYYY(date) {
      const dateConv = date?.length > 8 ? date.substring(2) : date
      return moment(`20${dateConv}`).format('YYYY-MM-DD')
    },
    switchLocale() {
      if (this.$i18n.locale !== 'en') {
        this.$i18n.locale = 'en'
      } else {
        this.$i18n.locale = 'th'
      }

      localStorage.setItem('language', this.$i18n.locale)
      window.location.reload()
    },
    updateTime() {
      this.time = new Date();
    },
    async getWebSettingInfo() {
      try {
        let settingWebInfo = await this.$store.dispatch('getSettingWebInfo')
        this.bankSystem = settingWebInfo?.bank_system[0] || 'TH';
      } catch (e) { }
    },
    getCurrency() {
      return getCurrency(this.bankSystem);
    },
  },
  computed: {
    formattedTime() {
      return getFormattedTime(this.time, this.tz, 'YYYY-MM-DD HH:mm:ss');
    },
    formattedOffset() {
      return getFormattedOffset(this.time, this.tz);
    },
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}

.v-max-sheet {
  max-height: 82vh;
}

.app-content-p {
  padding: 24px;
}

@media (max-width: 550px) {
  .app-content-p {
    padding: 8px;
  }

  #alert-sound {
    display: none;
  }
}
</style>
