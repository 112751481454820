import { AUTH_API_BASE_URL, urlFor, request } from '../index'

export async function getBonus(text) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/bonus?${text}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getBonusTotal() {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/bonus/total`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}
