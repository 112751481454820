import * as RankingRewardService from '@/services/apis/rankingReward'
const state = {
}

const actions = {
  async getRankingReward({ }, params) {
    try {
      const res = await RankingRewardService.getRankingReward(params)
      return res.data
    } catch (err) {
      if (err.message) { throw JSON.parse(err.message) }
    }
  },
  async deleteRankingReward({ }, params) {
    try {
      const res = await RankingRewardService.deleteRankingReward(params)
      return res.data
    } catch (err) {
      if (err.message) { throw JSON.parse(err.message) }
    }
  },
  async addRankingReward({ }, data) {
    try {
      const res = await RankingRewardService.addRankingReward(data)
      return res.data
    } catch (err) {
      if (err.message) { throw JSON.parse(err.message) }
    }
  },
}

export default {
  state,
  actions,
}
