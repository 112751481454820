import * as AccountBankService from '@/services/apis/accountBank'
const state = {
    bank: [
        {
            name: 'ธนาคารกรุงเทพ',
            code: 'BBL',
        },
        {
            name: 'ธนาคารกสิกรไทย',
            code: 'KBANK',
        },
        {
            name: 'ธนาคาร เดอะรอยัลแบงค์อ๊อฟสกอตแลนด์',
            code: 'RBS',
        },
        {
            name: 'ธนาคารกรุงไทย',
            code: 'KTB',
        },
        {
            name: 'JP Morgan Chase Bank',
            code: 'JPM',
        },
        {
            name: 'ธนาคารแห่งโตเกียว-มิตซูบิชิ ยูเอฟเจ',
            code: 'MUFG',
        },
        {
            name: 'ธนาคารทหารไทยธนชาต',
            code: 'TTB',
        },
        {
            name: 'ธนาคารไทยพาณิชย์ (SCB)',
            code: 'SCB',
        },
        {
            name: 'ธนาคารซิตี้แบงค์',
            code: 'CITI',
        },
        {
            name: 'ธนาคาร SMBC',
            code: 'SMBC',
        },
        {
            name: 'Standard Chartered',
            code: 'SC',
        },
        {
            name: 'ธนาคารซีไอเอ็มบีไทย',
            code: 'CIMB',
        },
        {
            name: 'ธนาคารยูโอบี',
            code: 'UOB',
        }, {
            name: 'ธนาคารกรุงศรีอยุธยา',
            code: 'BAY',
        },
        {
            name: 'ธนาคาร เมกะ สากลพาณิชย์',
            code: 'MEGA',
        },
        {
            name: 'Bank of America',
            code: 'BOA',
        },
        {
            name: 'Crédit Agricole Corporate and Investment Bank',
            code: 'CACIB',
        },
        {
            name: 'ธนาคารออมสิน',
            code: 'GSB',
        },
        {
            name: 'ธนาคารเอชเอสบีซี(HSBC)',
            code: 'HSBC',
        },
        {
            name: 'ธนาคารดอยซ์ แบงก์',
            code: 'DB',
        },
        {
            name: 'ธนาคารอาคารสงเคราะห์',
            code: 'GHB',
        },

        {
            name: 'ธนาคารเพื่อการเกษตรและสหกรณ์การเกษตร',
            code: 'BAAC',
        }, {
            name: 'ธนาคาร MB',
            code: 'MB',
        }, {
            name: 'ธนาคารบีเอ็นพี พารีบาส์',
            code: 'BNP',
        }, {
            name: 'ธนาคารธนชาต',
            code: 'TBANK',
        }, {
            name: 'ธนาคารอิสลามแห่งประเทศไทย',
            code: 'IBANK',
        }, {
            name: 'ธนาคารทิสโก้',
            code: 'TISCO',
        }, {
            name: 'ธนาคารเกียรตินาคินภัทร จำกัด (มหาชน)',
            code: 'KK',
        }, {
            name: 'ธนาคารพาณิชย์อุตสาหกรรมแห่งประเทศจีน',
            code: 'ICBC',
        }, {
            name: 'ธนาคารไทยเครดิต เพื่อรายย่อย',
            code: 'TCRB',
        }, {
            name: 'ธนาคารแลนด์ แอนด์ เฮ้าส์',
            code: 'LHB',
        },
        {
            name: 'ทรูมันนี่ วอลเล็ท',
            code: 'TRUEWALLET',
        },
    ]
}

const actions = {
    async getAccountBank({ }, params) {
        try {
            let text = ""
            for (let i in params) {
                text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
            }
            const res = await AccountBankService.getAccountBank(text)
            return res.data
        } catch (err) {
            if(err.message){
                if(err.message){ if(err.message){ throw JSON.parse(err.message) } }
            }
        }
    },
    async addAccountBank({ }, data) {
        try {
            const res = await AccountBankService.addAccountBank(data)
            if (res.status === 201) {
                return {}
                // await dispatch('permissions');
            } else {
                throw Error(JSON.stringify({ message: res.data.message }));
            }

        } catch (err) {
            if(err.message){ throw JSON.parse(err.message) }
        }
    },
    async updateAccountBank({ }, data) {
        try {
            const res = await AccountBankService.updateAccountBank(data)
            if (res.status === 200) {
                return {}
                // await dispatch('permissions');
            } else {
                throw Error(JSON.stringify({ message: res.data.message }));
            }
        } catch (err) {
            if(err.message){ throw JSON.parse(err.message) }
        }
    },
    async deleteAccountBank({ }, is) {
        try {
            const res = await AccountBankService.deleteAccountBank(is)
            if (res.status === 204) {
                return {}
                // await dispatch('permissions');
            } else {
                throw Error(JSON.stringify({ message: res.data.message }));
            }
        } catch (err) {
            if(err.message){ throw JSON.parse(err.message) }
        }
    },
    async scbRequestOTP({ }, data) {
        try {
            const res = await AccountBankService.scbRequestOTP(data)
            return res
        } catch (err) {
            if(err.message){ throw JSON.parse(err.message) }
        }
    },
    async getTransactionBanks({ }, params) {
        try {
            let text = ""
            for (let i in params) {
                text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
            }
            const res = await AccountBankService.getTransactionBanks(text)
            return res.data
        } catch (err) {
            if(err.message){ throw JSON.parse(err.message) }
        }
    },
    
}

export default {
    state,
    actions,
}