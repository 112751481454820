import { AUTH_API_BASE_URL, urlFor, request } from '../index'

export async function getVideoList(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/videos?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function addVideo(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/videos/add`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}

export async function updateVideo(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/videos/update`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}

export async function removeVideo(data) {
  try {
    return request('delete', urlFor(AUTH_API_BASE_URL, `/videos/${data.id}`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}




