import { AUTH_API_BASE_URL, urlFor, request } from '../index'

export async function getParnerReport(text) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/partner/report?${text}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}
export async function getParnerReportBox(text) {
    try {
      return request('get', urlFor(AUTH_API_BASE_URL, `/partner/report/box?${text}`), {
        auth: true,
      })
    } catch (e) {
      return e
    }
  }