import * as MineSweeperService from '@/services/apis/mineSweeper'
const state = {}

const actions = {
  async getMineSweeperData({ }, params) {
    try {
      const res = await MineSweeperService.getMineSweeperData(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },

  async saveMineSweeperData({ }, params) {
    try {
      const res = await MineSweeperService.saveMineSweeperData(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
}

export default {
  state,
  actions,
}
