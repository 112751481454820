import { AUTH_API_BASE_URL, urlFor, request } from '../index'

export async function verifyLineOtp(adminId, otp, reference) {
  // console.log('API Service: verifyLineOtp called with:', { adminId, otp, reference });
  // Makes an API call to verify the OTP code entered by the admin
  // Parameters:
  // - adminId: The ID of the admin attempting to verify
  // - otp: The one-time password received via Line
  // - reference: A reference code for this verification attempt
  try {
    const response = await request('post', urlFor(AUTH_API_BASE_URL.replace('/admin', ''), '/auth-line-otp/verify'), {
      data: { adminId, otp, reference }
    });
    // console.log('API Service: verifyLineOtp response:', response);
    return response;
  } catch (e) {
    console.error('API Service: verifyLineOtp error:', e);
    if (e.message && typeof e.message === 'string') {
      try {
        const parsedError = JSON.parse(e.message);
        throw parsedError;
      } catch (parseError) {
        throw new Error(e.message);
      }
    }
    throw e;
  }
}


export async function loginWithOtp(username, password, otp, reference) {
  // console.log('API Service: loginWithOtp called with:', { username, password, otp });
  // Handles the actual login process using OTP
  // Parameters:
  // - username: Admin's username
  // - password: Admin's password
  // - otp: The verification code received via Line
  try {
    console.log('API Service: loginWithOtp called with:', { 
      username, 
      password, 
      otp, 
      reference 
    });

    const response = await request('post', urlFor(AUTH_API_BASE_URL.replace('/admin', ''), '/auth-line-otp/login'), {
      data: {
        username,
        password,
        otp,
        reference
      }
    });

    console.log('API Service: loginWithOtp response:', response);
    return response;
  } catch (e) {
    console.error('API Service: loginWithOtp error:', e);
    if (e.message && typeof e.message === 'string') {
      try {
        const parsedError = JSON.parse(e.message);
        throw parsedError;
      } catch (parseError) {
        throw new Error(e.message);
      }
    }
    throw e;
  }
}

export async function setAdminLineOtpStatus(data) {
  // Enables/disables Line OTP for a specific admin
  // Parameters:
  // - data: Object containing:
  //   - id: The admin's ID
  //   - status: Boolean/Number indicating whether to enable (1) or disable (0) Line OTP
  try {
    return request('patch', urlFor(AUTH_API_BASE_URL.replace('/admin', ''), 'auth-line-otp/set-admin-line-otp-status'), {
      auth: true, // This ensures JWT token is sent
      data: {
        adminId: data.id,  // Change from id to adminId to match backend
        status: data.status
      }
    });
  } catch (e) {
    throw e;
  }
}