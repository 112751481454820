import { AUTH_API_BASE_URL, urlFor, request } from '../index'

export async function getDailyLogin(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/daily-login/list?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getDailyLoginReward(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/daily-login/reward?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function addDailyLogin(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/daily-login/add`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}
