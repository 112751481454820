import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate';
import auth from './auth'
import coupon from './coupon'
import transaction from './transaction'
import accountBank from './accountBank'
import logs from './logs'
import select from './select'
import settingWeb from './settings/web'
import employee from './employee'
import manageRole from './manageRole'
import member from './member'
import news from './news'
import promotion from './promotion'
import dashboard from './dashboard'
import affiliate from './affiliate'
import report from './report'
import loader from './loader'
import bonus from './bonus'
import accountingReport from './accountingReport';
import twoFactorAuth from './twoFactorAuth';
import dailyLogin from './dailyLogin';
import luckyCard from './luckyCard';
import predictLotto from './predictLotto';
import predictFootball from './predictFootball';
import announce from './announce';
import messageBoard from './messageBoard';
import pointCoin from './pointCoin';
import user from './user';
import bonusExp from './bonusExp';
import spadeHigh from './spadeHigh';
import paykrubTransferOut from './paykrubTransferOut';
import paykrubTransferOutAdd from './paykrubTransferOutAdd';
import mineSweeper from './mineSweeper';
import video from './video';
import redeemReward from './redeemReward';
import lineOtpAuth from './lineOtpAuth';
import telegramOtpAuth from './telegramOtpAuth';
import activityBanner from './activityBanner';
import rankingReward from './rankingReward';

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    coupon,
    transaction,
    accountBank,
    logs,
    select,
    settingWeb,
    employee,
    manageRole,
    member,
    news,
    promotion,
    dashboard,
    affiliate,
    report,
    loader,
    bonus,
    accountingReport,
    twoFactorAuth,
    dailyLogin,
    luckyCard,
    predictLotto,
    predictFootball,
    announce,
    messageBoard,
    pointCoin,
    user,
    bonusExp,
    spadeHigh,
    paykrubTransferOut,
    paykrubTransferOutAdd,
    mineSweeper,
    video,
    redeemReward,
    lineOtpAuth,
    telegramOtpAuth,
    activityBanner,
    rankingReward,
  },
  plugins: [createPersistedState()],
})
