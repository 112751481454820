import { AUTH_API_BASE_URL, urlFor, request } from '../index';

export async function getManageRole(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/manage-role?${params}`), { //`/manage-role?page=${page}&rows=${row}`
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function addRole(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, '/manage-role/add/role'), {
      auth: true,
      data: data
    });
  } catch (e) {
    return e
  }
}

export async function updateRole(data) {
  try {
    // return request('patch', urlFor(AUTH_API_BASE_URL, '/manage-role/update/role'), data), {
    //   auth: true
    // });
  } catch (e) {
    return e
  }
}

export async function updatePermission(data) {
  try {
    return request('patch', urlFor(AUTH_API_BASE_URL, '/manage-role/update/premission'), {
      auth: true,
      data: data
    });
  } catch (e) {
    return e
  }
}

export async function updatePermissionEdit(data) {
  try {
    return request('patch', urlFor(AUTH_API_BASE_URL, '/manage-role/update/premission-edit'), {
      auth: true,
      data: data
    });
  } catch (e) {
    return e
  }
}

export async function deleteRole(id) {
  try {
    return request('delete', urlFor(AUTH_API_BASE_URL, `/manage-role/${id}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}