import * as UserService from '@/services/apis/user'
const state = {
}

const actions = {
    async addUser({ }, params) {
        try {
          const res = await UserService.addUser(params)
          if (res.status === 201 || res.status === 200) {
            return {}
          } else {
            throw Error(JSON.stringify({ message: res.data.message }));
          }

        } catch (err) {
          if(err.message){ throw JSON.parse(err.message) }
        }
      },
}

export default {
    state,
    actions,
}
