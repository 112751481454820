
import * as TwoFactorAuthService from '@/services/apis/twoFactorAuth'
const state = {
  token: null,
}

const getters = {
  getToken: (state) => {
      return state.token
  },
}

const mutations = {
  updateToken: (state, payload) => {
      state.token = payload
  },
}

const actions = {
  async register2fa({ }, params) {
    try {
      let res = await TwoFactorAuthService.register2fa(params)
      return res.data
    } catch (err) {
        if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async login2fa({ commit, dispatch }, params) {
    try {
      let res = await TwoFactorAuthService.login2fa(params)
        if (res.status === 201) {
            commit('updateToken', res.data.access_token);
            // await dispatch('permissions');
        } else {
            throw Error(JSON.stringify({ message: res.data.message }));
        }
    } catch (err) {
        if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async generate2fa({ }, params) {
    try {
      let res = await TwoFactorAuthService.generate2fa(params)
      return res.data
    } catch (err) {
        if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async verify2fa({ }, params) {
    try {
      let res = await TwoFactorAuthService.verify2fa(params)
      return res.data
    } catch (err) {
        if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async deleteGenerate2fa({ }, params) {
    try {
      let res = await TwoFactorAuthService.deleteGenerate2fa(params)
      return res.data
    } catch (err) {
        if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async delete2fa({ }, params) {
    try {
      let res = await TwoFactorAuthService.delete2fa(params)
      return res.data
    } catch (err) {
        if(err.message){ throw JSON.parse(err.message) }
    }
  },
}

export default {
  state,
  actions,
}
