import * as telegramOtpAuth from '@/services/apis/telegramOtpAuth'

const state = {
  loginCredentials: {
    username: null,
    password: null
  }
}

const mutations = {
  SET_LOGIN_CREDENTIALS(state, { username, password }) {
    state.loginCredentials = { username, password }
  },
  CLEAR_LOGIN_CREDENTIALS(state) {
    state.loginCredentials = {
      username: null,
      password: null
    }
  }
}

const getters = {
  getLoginCredentials: state => state.loginCredentials
}

const actions = {
  async verifyTelegramOtp({ state, rootState }, { otp, reference }) {
    try {
      const { username, password } = state.loginCredentials;
      console.log('Store: verifyTelegramOtp called with:', {
        username,
        password,
        otp,
        reference
      });
  
      const response = await telegramOtpAuth.loginWithOtp(
        username,
        password,
        otp,
        reference
      );
  
      if (response?.status === 201 && response?.data?.access_token) {
        return {
          data: response.data,
          status: response.status
        };
      } else {
        throw new Error(JSON.stringify({
          message: response?.data?.message || 'No access token received',
          status: response?.status,
          data: response?.data
        }));
      }
    } catch (err) {
      console.error('Store: verifyTelegramOtp error:', err);
      throw err;
    }
  },

  async setAdminTelegramOtpStatus({ }, data) {
    try {
      return await telegramOtpAuth.setAdminTelegramOtpStatus(data)
    } catch (err) {
      console.error('Set Admin Telegram OTP Status Error:', err);
      throw err
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions
}