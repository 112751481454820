import { AUTH_API_BASE_URL, urlFor, request } from '../index';

export async function getAccountBank(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/accountbank?${params}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function addAccountBank(data) {
    try {
      return request('post', urlFor(AUTH_API_BASE_URL, '/accountbank/add'), {
        auth: true,
        data
      });
    } catch (e) {
      return e
    }
  }

  export async function updateAccountBank(data) {
    try {
      return request('patch', urlFor(AUTH_API_BASE_URL, '/accountbank/update'), {
        auth: true,
        data
      });
    } catch (e) {
      return e
    }
  }
  export async function deleteAccountBank(id) {
    try {
      return request('delete', urlFor(AUTH_API_BASE_URL, `/accountbank/${id}`), {
        auth: true
      });
    } catch (e) {
      return e
    }
  }
  export async function scbRequestOTP(data) {
    try {
      return request('post', urlFor(AUTH_API_BASE_URL, '/accountbank/scb/getotp'), {
        auth: true,
        data
      });
    } catch (e) {
      return e
    }
  }
  export async function getTransactionBanks(params) {
    try {
      return request('get', urlFor(AUTH_API_BASE_URL, `/accountbank/transaction?${params}`), {
        auth: true
      });
    } catch (e) {
      return e
    }
  }