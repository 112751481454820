import * as EmployeeService from '@/services/apis/employee'
const state = {
}

const actions = {
  async getEmployee({ }, params) {
    try {
      let text = ""
      for (let i in params) {
        text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
      }
      const res = await EmployeeService.getEmployee(text)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async addEmployee({ }, data) {
    try {
      const res = await EmployeeService.addEmployee(data)
      if (res.status === 201) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async updateEmployee({ }, data) {
    try {
      const res = await EmployeeService.updateEmployee(data)
      if (res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getEmployeeById({ }, id) {
    try {
      const res = await EmployeeService.getEmployeeById(id)
      if (res.status === 200) {
        return res.data
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }

    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async deleteemployee({ }, id) {
    try {
      const res = await EmployeeService.deleteEmployee(id)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
}

export default {
  state,
  actions,
}