import * as PointCoinService from '@/services/apis/pointCoin'
const state = {}

const actions = {
  async getPointCoinHistory({}, params) {
    try {
      let text = ''
      for (let i in params) {
        text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
      }
      const res = await PointCoinService.getPointCoinHistory(text)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async updatePointCoin({}, params) {
    try {
      const res = await PointCoinService.updatePointCoin(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
}

export default {
  state,
  actions,
}
