import { AUTH_API_BASE_URL, urlFor, request } from '../index';

export async function getEmployee(text) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/employee?${text}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function getEmployeeById(id) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/employee?page=1&rows=1&id=${id}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function addEmployee(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, '/employee/add'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}

export async function updateEmployee(data) {
  try {
    return request('patch', urlFor(AUTH_API_BASE_URL, '/employee/update'), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}
export async function deleteEmployee(id) {
  try {
    return request('delete', urlFor(AUTH_API_BASE_URL, `/employee/${id}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}