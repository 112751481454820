import * as ReportService from '@/services/apis/report'
const state = {}

const actions = {
  async getParnerReport({}, params) {
    try {
      let text = ''
      for (const i in params) {
        text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
      }
      const res = await ReportService.getParnerReport(text)

      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getParnerReportBox({}, params) {
    try {
      let text = ''
      for (const i in params) {
        text = text ? `${text}&${i}=${params[i]}` : `${i}=${params[i]}`
      }
      const res = await ReportService.getParnerReportBox(text)

      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
}

export default {
  state,
  actions,
}
