import { AUTH_API_BASE_URL, urlFor, request } from '../index';

export async function getSettingWebInfo() {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, 'setting-web/info'), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function updateSettingWebInfo(data) {
    try {
      return request('patch', urlFor(AUTH_API_BASE_URL, 'setting-web/update/info'), {
        auth: true,
        data
      });
    } catch (e) {
      throw e
    }
  }

  export async function getSettingWebColor() {
    try {
      return request('get', urlFor(AUTH_API_BASE_URL, 'setting-web/color'), {
        auth: true
      });
    } catch (e) {
      return e
    }
  }

  export async function updateSettingWebColor(data) {
    try {
      return request('patch', urlFor(AUTH_API_BASE_URL, 'setting-web/update/color'), {
        auth: true,
        data
      });
    } catch (e) {
      throw e
    }
  }

  export async function getSettingWebWheel() {
    try {
      return request('get', urlFor(AUTH_API_BASE_URL, 'setting-web/wheel'), {
        auth: true
      });
    } catch (e) {
      return e
    }
  }

  export async function updateSettingWebWheel(data) {
    try {
      return request('patch', urlFor(AUTH_API_BASE_URL, 'setting-web/update/wheel'), {
        auth: true,
        data
      });
    } catch (e) {
      throw e
    }
  }
  export async function getSettingWebAffiliate() {
    try {
      return request('get', urlFor(AUTH_API_BASE_URL, 'setting-web/affiliate'), {
        auth: true
      });
    } catch (e) {
      return e
    }
  }

  export async function updateSettingWebAffiliate(data) {
    try {
      return request('patch', urlFor(AUTH_API_BASE_URL, 'setting-web/update/affiliate'), {
        auth: true,
        data
      });
    } catch (e) {
      throw e
    }
  }
  export async function getSettingWebCashback() {
    try {
      return request('get', urlFor(AUTH_API_BASE_URL, 'setting-web/cashback'), {
        auth: true
      });
    } catch (e) {
      return e
    }
  }

  export async function updateSettingWebCashback(data) {
    try {
      return request('patch', urlFor(AUTH_API_BASE_URL, 'setting-web/update/cashback'), {
        auth: true,
        data
      });
    } catch (e) {
      throw e
    }
  }

  export async function getSettingGames() {
    try {
      return request('get', urlFor(AUTH_API_BASE_URL, 'setting-web/games'), {
        auth: true
      });
    } catch (e) {
      return e
    }
  }

  export async function updateSettingGames(data) {
    try {
      return request('patch', urlFor(AUTH_API_BASE_URL, 'setting-web/update/games'), {
        auth: true,
        data
      });
    } catch (e) {
      throw e
    }
  }

  export async function getWebInfo(data) {
    try {
      return request('get', urlFor(AUTH_API_BASE_URL.replace('/admin',''), 'web-setting'), {
        auth: false
      });
    } catch (e) {
      throw e
    }
  }

  export async function putSettingUpload(data) {
    try {
        return request('put', urlFor(AUTH_API_BASE_URL, 'setting-web/upload'), {
        auth: true,
        data
      });

    } catch (e) {
      throw e
    }
  }

  export async function getSettingFeature() {
    try {
      return request('get', urlFor(AUTH_API_BASE_URL, 'setting-web/feature-enabled'), {
        auth: true
      });
    } catch (e) {
      throw e
    }
  }

  export async function postSettingFeature(data) {
    try {
      return request('post', urlFor(AUTH_API_BASE_URL, 'setting-web/feature-update'), {
        auth: true,
        data
      });

    } catch (e) {
      throw e
    }
  }

  export async function putSettingOtpAdmin(data) {
    try {
      return request('put', urlFor(AUTH_API_BASE_URL, `logger/sms-admin`), {
        auth: true,
        data
      });

    } catch (e) {
      throw e
    }    
  }

  export async function getLoginMethod() {
    try {
      return request('get', urlFor(AUTH_API_BASE_URL, 'setting-web/login-method'), {
        auth: true
      });
    } catch (e) {
      return e
    }
  }
  
  export async function updateLoginMethod(data) {
    try {
      const response = await request('patch', urlFor(AUTH_API_BASE_URL, 'setting-web/update/login-method'), {
        auth: true,
        data
      });
      return response;
    } catch (e) {
      throw e
    }
  }

  export async function getAvailableLoginMethods() {
    try {
      return request('get', urlFor(AUTH_API_BASE_URL, 'setting-web/available-login-methods'), {
        auth: true
      });
    } catch (e) {
      return e;
    }
  }