import { AUTH_API_BASE_URL, urlFor, request } from '../index';

export async function getCoupon(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/coupon?${params}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function getCashback(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/cashback?${params}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function getCashbackTotal(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/cashback/total?time_from=${params.time_from}&time_to=${params.time_to}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function getCouponDetail(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/coupon/detail?${params}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function addCoupon(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, '/coupon/add'), {
      auth: true,
      data: data
    });


    return "";
  } catch (e) {
    return e
  }
}

export async function deleteCoupon(params) {
  try {
    return request('delete', urlFor(AUTH_API_BASE_URL, `/coupon/delete-coupon?${params}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function deleteCouponSet(params) {
  try {
    return request('delete', urlFor(AUTH_API_BASE_URL, `/coupon/delete-coupon-set?${params}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function getCouponDetailUsed(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/coupon/used?${params}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}
