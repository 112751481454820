import { AUTH_API_BASE_URL, urlFor, request } from '../index';

export async function getRankingReward(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/ranking-reward/list?${params}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function deleteRankingReward(params) {
  console.log(params)
  try {
    return request('delete', urlFor(AUTH_API_BASE_URL, `/ranking-reward/delete?id=${params}`), {
      auth: true
    });
  } catch (e) {
    return e
  }
}

export async function addRankingReward(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, '/ranking-reward/add'), {
      auth: true,
      data: data
    });


    return "";
  } catch (e) {
    return e
  }
}

