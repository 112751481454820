import { AUTH_API_BASE_URL, urlFor, request } from '../index'

export async function getMineSweeperData(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/minesweeper/list?name=minesweeper`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function saveMineSweeperData(params) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/minesweeper/add?name=minesweeper`), {
      auth: true,
      data: params,
    })
  } catch (e) {
    return e
  }
}


