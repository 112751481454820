import * as VideoService from '@/services/apis/video'
const state = {}

const actions = {
  async getVideoList({}, params) {
    try {
      const res = await VideoService.getVideoList(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async addVideo({}, params) {
    try {
      const res = await VideoService.addVideo(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async updateVideo({}, params) {
    try {
      const res = await VideoService.updateVideo(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async removeVideo({}, params) {
    try {
      const res = await VideoService.removeVideo(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
}

export default {
  state,
  actions,
}
