import * as DailyLoginService from '@/services/apis/dailyLogin'
const state = {}

const actions = {
  async getDailyLogin({}, params) {
    try {
      const res = await DailyLoginService.getDailyLogin(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async getDailyLoginReward({}, params) {
    try {
      const res = await DailyLoginService.getDailyLoginReward(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async addDailyLogin({}, data) {
    try {
      const res = await DailyLoginService.addDailyLogin(data)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
}

export default {
  state,
  actions,
}
