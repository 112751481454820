import { AUTH_API_BASE_URL, urlFor, request } from '../index'

export async function getRedeemReward(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/redeem-reward/reward-list?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getRedeemRewardHistory(params) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/redeem-reward/redeem-list?${params}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function updateRedeemRewardStatus(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/redeem-reward/update-reward`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}

export async function addRedeemReward(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/redeem-reward/create-reward`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}

export async function removeRedeemReward(id) {
  try {
    return request('delete', urlFor(AUTH_API_BASE_URL, `/redeem-reward/delete-reward?id=` + id), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function updateRedeemRewardHistoryStatus(data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/redeem-reward/update-redeem-reward`), {
      auth: true,
      data: data,
    })
  } catch (e) {
    return e
  }
}
