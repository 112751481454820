import * as PromotionService from '@/services/apis/promotion'
const state = {
}

const actions = {
  async getPromotion({ }, params) {
    try {
      const res = await PromotionService.getPromotion(params)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async addPromotion({ }, data) {
    try {
      const res = await PromotionService.addPromotion(data)
      if (res.status === 201) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async updatePromotion({ }, data) {
    try {
      const res = await PromotionService.updatePromotion(data)
      if (res.status === 200) {
        return {}
      } else {
        throw Error(JSON.stringify({ message: res.data.message }));
      }
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async deletePromotion({ }, id) {
    try {
      const res = await PromotionService.deletePromotion(id)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
  async getPromotionUse({ }, id) {
    try {
      const res = await PromotionService.getPromotionUse(id)
      return res.data
    } catch (err) {
      if(err.message){ throw JSON.parse(err.message) }
    }
  },
}

export default {
  state,
  actions,
}
