import { AUTH_API_BASE_URL, urlFor, request } from '../index'

export async function getMember(text) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/member?${text}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getPartner(text) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/partner?${text}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getPartnerRef(id, text) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/partner/${id}/ref?${text}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getPartnerCommission(id, text) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/partner/${id}/commission?${text}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getPartnerRefMemberReport(id, text) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/partner/${id}/ref-member-report?${text}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getMemberTurnOver(id) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/member/turnover/${id}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function ClearTurnOver(id) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/member/clean/turnover/${id}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function customClearTurnOver(id, data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/member/custom-clear/turnover/${id}`), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}

export async function customUpdateTurnOver(id, data) {
  try {
    return request('post', urlFor(AUTH_API_BASE_URL, `/member/custom-update/turnover/${id}`), {
      auth: true,
      data
    });
  } catch (e) {
    return e
  }
}

export async function getMemberWallet(id) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/member/wallet/${id}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getMemberDepositWithdraw(id) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/member/deposit-withdraw/${id}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getMemberAffiliate(text) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/member/affiliate?${text}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function updateMember(data) {
  try {
    return request('patch', urlFor(AUTH_API_BASE_URL, '/member/update'), {
      auth: true,
      data,
    })
  } catch (e) {
    return e
  }
}

export async function deleteMember(id) {
  try {
    return request('delete', urlFor(AUTH_API_BASE_URL, `/member/${id}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function createGame(id) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/member/create/game/${id}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}

export async function getPartnerour(id) {
  try {
    return request('get', urlFor(AUTH_API_BASE_URL, `/partner/check_partner/?id=${id}`), {
      auth: true,
    })
  } catch (e) {
    return e
  }
}
