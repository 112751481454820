import Axios from 'axios';
import urljoin from 'url-join';
//import config from '@/configs';
import store from '@/store'
import getEnv from "./env.js";


export const AUTH_API_BASE_URL = getEnv("VUE_APP_API_BASE_URL") ? getEnv("VUE_APP_API_BASE_URL") : 'http://localhost:3000/admin';

/*
const urlCurrent = window.location
const baseApiCurrent = urlCurrent.protocol + '//api' + urlCurrent.hostname.substring(5) + '/admin';

export const AUTH_API_BASE_URL = process.env.NODE_ENV === "development" ? config[process.env.NODE_ENV].AUTH_API_BASE_URL : baseApiCurrent;
// export const AUTH_API_BASE_URL = config[process.env.NODE_ENV].AUTH_API_BASE_URL;

*/
export function urlFor(hostname, path) {
    return urljoin(hostname, path);
}

export function getHeaders(auth) {
    if (!auth) {
        return { 'Content-Type': 'application/json' };
    }
    let headers = { 'Content-Type': 'application/json' };
    const token = store.getters.getToken;
    if (token) {
        headers['Authorization'] = `Bearer ${token}`;
    }
    return headers;
}

export async function handleAxiosError(err) {
    if (err.response && err.response.status === 401) {
        await store.dispatch('logout')
    }
    return err.response;
}

export async function request(method, url, req) {
    const headers = getHeaders(req.auth);
    try {
        const res = await Axios({
            method,
            url,
            headers: headers,
            data: req.data,
            params: req.params
        });

        return res;
    } catch (e) {
        return await handleAxiosError(e);
    }
}
