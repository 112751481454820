import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import VueSweetalert2 from 'vue-sweetalert2'
import router from './router'
import store from './store'
import config from '@/configs'
import 'sweetalert2/dist/sweetalert2.min.css'
import Vuex from 'vuex'
import vueDebounce from 'vue-debounce'
import i18n from './i18n'
import io from 'socket.io-client'

Vue.use(Vuex)
Vue.use(vueDebounce)
Vue.use(VueSweetalert2)

Vue.filter('currency', value => parseFloat(value).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','))

// Version
console.log('Version 2 (build 1117)');

Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: h => h(App),
}).$mount('#app')
