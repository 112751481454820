import { AUTH_API_BASE_URL, urlFor, request } from '../index';

export async function login(params) {
    try {
        let res = request('post', urlFor(AUTH_API_BASE_URL, '/login'), {
            data: params
        });
        return res
    } catch (e) {
        return e
    }

}

export async function permissions(params) {
    try {
        let res = request('get', urlFor(AUTH_API_BASE_URL, '/checkauth'), {
            auth: true,
            data: params
        });
        return res
    } catch (e) {
        return e
    }

}

export const login2fa = async (username, password, pin) => {
    try {
      const response = await request('post', urlFor(AUTH_API_BASE_URL, '/login2fa'), {
        data: { username, password, pin },
        auth: true
      });
      return response;
    } catch (error) {
      console.error('2FA Login Service Error:', error);
      throw error;
    }
  }