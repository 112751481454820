import * as PredictLottoService from '@/services/apis/predictLotto'
const state = {}

const actions = {
  async getLottoList({}, params) {
    try {
      const res = await PredictLottoService.getLottoList(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async addLotto ({}, params) {
    try {
      const res = await PredictLottoService.addLotto(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async updateResultLotto({}, params) {
    try {
      const res = await PredictLottoService.updateResultLotto(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async removeLotto({}, params) {
    try {
      const res = await PredictLottoService.removeLotto(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async transferLottoRewardToMember({}, params) {
    try {
      const res = await PredictLottoService.transferLottoRewardToMember(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async getLottoGameUser({}, params) {
    try {
      const res = await PredictLottoService.getLottoGameUser(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
  async getLottoGameUserReward({}, params) {
    try {
      const res = await PredictLottoService.getLottoGameUserReward(params)
      return res.data
    } catch (err) {
      if (err.message) {
        throw JSON.parse(err.message)
      }
    }
  },
}

export default {
  state,
  actions,
}
