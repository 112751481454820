const urlCurrent = window.location
const baseApiCurrent = urlCurrent.protocol + '//api' + urlCurrent.hostname.substring(5) + '/admin';
module.exports = {
    production: {
        AUTH_API_BASE_URL: baseApiCurrent,
    },
    development: {
        AUTH_API_BASE_URL:
            // 'https://api.testak.space/admin',
            // 'https://api.akokbet.net/admin',
            // 'https://api.akfc.co/admin',
            'http://localhost:3000/admin',
    },

};
