export function getCurrency(curencyText) {
    if (curencyText == "TH") {
        return "บาท";
    } else if (curencyText == "KR") {
        return "₩";
    } else if (curencyText == "KH") {
        return "$";
    } else if (curencyText == "CN") {
        return "¥";
    } else if (curencyText == "TW") {
        return "$";
    } else if (curencyText == "VN") {
        return "₫";
    } else if (curencyText == "LA") {
        return "$";
    } else {
        return "$";
    }
}

export function getCurrencyI18n(curencyText, message) {
    if (curencyText == "TH") {
        message = message.toString().replace("บาท", "บาท");
        message = message.toString().replace("Baht", "Baht");
        message = message.toString().replace("baht", "baht");
        return message;
    } else if (curencyText == "KR") {
        message = message.toString().replace("บาท", "₩");
        message = message.toString().replace("Baht", "₩");
        message = message.toString().replace("baht", "₩");
        return message;
    } else if (curencyText == "KH") {
        message = message.toString().replace("บาท", "$");
        message = message.toString().replace("Baht", "$");
        message = message.toString().replace("baht", "$");
        return message;
    } else if (curencyText == "CN") {
        message = message.toString().replace("บาท", "¥");
        message = message.toString().replace("Baht", "¥");
        message = message.toString().replace("baht", "¥");
        return message;
    } else if (curencyText == "TW") {
        message = message.toString().replace("บาท", "$");
        message = message.toString().replace("Baht", "$");
        message = message.toString().replace("baht", "$");
        return message;
    } else if (curencyText == "VN") {
        message = message.toString().replace("บาท", "₫");
        message = message.toString().replace("Baht", "₫");
        message = message.toString().replace("baht", "₫");
        return message;
    } else if (curencyText == "LA") {
        message = message.toString().replace("บาท", "$");
        message = message.toString().replace("Baht", "$");
        message = message.toString().replace("baht", "$");
        return message;
    } else {
        message = message.replace("บาท", "$");
        message = message.replace("Baht", "$");
        message = message.replace("baht", "$");
        return message;
    }
}